import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Lock } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import getTotalAvailablePoints from '../../helpers/totalAvailablePoints';
import URL from '../../constants/urls';
import { getFormattedNumber } from '../../formatter/number';
import useMobileDevice from '../../hooks/useMobileDevice';
import UI from '../../constants/ui';
import { getLoginDetailFromSession } from '../../helpers/sessionDetails';
import LOGIN_TYPE from '../../constants/loginType';
import { EMPLOYER_LOGIN_ESTATE } from '../../constants/loginStates.constants';

export default function AvailablePoints() {
  const availablePoints = useSelector(
    (state) => state.user.userInfo.availablePoints
  );
  const reRenderPermissionsCode = useSelector(
    (state) => state.user.reRenderPermissionsCode
  );
  const isMobileDevice = useMobileDevice();
  const navigate = useNavigate();
  const userDetail = useMemo(
    () => getLoginDetailFromSession(),
    [reRenderPermissionsCode]
  );

  return (
    <span
      className={`${
        userDetail?.role === LOGIN_TYPE.CANDIDATE ||
        (userDetail?.project &&
          userDetail?.estate !== EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING)
          ? 'background-F3F3F3 '
          : 'background-D7D7D7'
      }  p-2 d-flex align-items-center me-2`}
      style={{ borderRadius: 20, cursor: 'pointer' }}
      onClick={() => {
        if (
          userDetail?.role === LOGIN_TYPE.CANDIDATE ||
          (userDetail?.project &&
            userDetail?.estate !== EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING)
        )
          navigate(URL.PAYMENT);
      }}
    >
      {userDetail?.role === LOGIN_TYPE.CANDIDATE ||
      (userDetail?.project &&
        userDetail?.estate !== EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING) ? (
        <CardGiftcardIcon sx={{ color: '#F2B807' }} />
      ) : (
        <Lock sx={{ color: 'white' }} />
      )}
      <span className="ms-1">
        {!isMobileDevice && UI.CREDIT_POINTS}{' '}
        {getFormattedNumber(getTotalAvailablePoints(availablePoints))}
      </span>
    </span>
  );
}
