export const BUTTON_TYPE = {
  RENEW: 1,
  ADD_POINTS: 2,
};
export const PlanType = {
  MONTHLY: 1,
  YEARLY: 2,
};

export const PAY_PER_USER_PLANID = 1;
