import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Pagination from '@mui/material/Pagination';
import DesktopViewTransaction from './DesktopViewTransaction';
import MobileTransactionView from './MobileTransactionView';
import ErrorSnackBar from '../../../components/snackBar/ErrorSnackBar';
import useMobileDevice from '../../../hooks/useMobileDevice';
import {
  DEFAULT_SELECTED_PAGE,
  EMPTY_OBJECT,
  PAGINATION_ROWS,
} from '../../../constants';
import Content from '../../../components/content';
import { portalScrollTo } from '../../../utils/scroll';

export default function TransactionHistory(props) {
  const {
    setOpen,
    isTransactionSkeletonShow,
    opensnackbar,
    handleClose,
    open,
  } = props;

  const [selectedPage, setSelectedPage] = useState(DEFAULT_SELECTED_PAGE);
  const isMobileDevice = useMobileDevice();
  const transaction = useSelector(
    (state) => state.user.userInfo.transactionHistory
  );

  const transactionDataShow = useMemo(
    () =>
      transaction.slice(
        (selectedPage - 1) * PAGINATION_ROWS,
        (selectedPage - 1) * PAGINATION_ROWS + PAGINATION_ROWS
      ),
    [transaction, selectedPage]
  );

  useEffect(() => {
    setSelectedPage(DEFAULT_SELECTED_PAGE);
  }, [transaction]);

  const myRef = useRef();

  return (
    <div ref={myRef} className="mt-2 card-border">
      {isMobileDevice ? (
        <MobileTransactionView
          setOpen={setOpen}
          transaction={transaction}
          isTransactionSkeletonShow={isTransactionSkeletonShow}
          open={open}
          selectedPage={selectedPage}
          transactionDataShow={transactionDataShow}
        />
      ) : (
        <DesktopViewTransaction
          setOpen={setOpen}
          transaction={transaction}
          isTransactionSkeletonShow={isTransactionSkeletonShow}
          open={open}
          selectedPage={selectedPage}
          transactionDataShow={transactionDataShow}
        />
      )}

      {open && (
        <Content condition={transaction.length > PAGINATION_ROWS}>
          {isMobileDevice ? (
            <Pagination
              count={Math.ceil(transaction.length / PAGINATION_ROWS)}
              boundaryCount={0}
              siblingCount={1}
              className="justify-content-center d-flex py-2"
              onChange={(_, page) => {
                portalScrollTo(myRef);
                setSelectedPage(page);
              }}
              page={selectedPage}
            />
          ) : (
            <Pagination
              count={Math.ceil(transaction.length / PAGINATION_ROWS)}
              className="justify-content-center d-flex py-2"
              showFirstButton
              showLastButton
              onChange={(_, page) => {
                portalScrollTo(myRef);
                setSelectedPage(page);
              }}
              page={selectedPage}
            />
          )}
        </Content>
      )}
      <ErrorSnackBar opensnackbar={opensnackbar} handleClose={handleClose} />
    </div>
  );
}

TransactionHistory.propTypes = {
  isTransactionSkeletonShow: PropTypes.bool,
  opensnackbar: PropTypes.object,
  handleClose: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
};

TransactionHistory.defaultProps = {
  isTransactionSkeletonShow: false,
  opensnackbar: EMPTY_OBJECT,
  handleClose: noop,
  setOpen: noop,
  open: false,
};
