export const PROJECT_USERS = {
  JOINED: 1,
  APPROVAL_PENDING: 2,
  REJECTED: 3,
  NOT_JOINED: 4,
  REGISTRATION_PENDING: 5,
};

export const PROJECT_ROLES_TYPE = {
  ROLES: 0,
  HROLE: 1,
};

export const PROJECT_STATE = {
  LIVE: 1,
  MARKED_DELETE: 2,
  READY_TO_DELETE: 3,
  DELETE: 4,
};
