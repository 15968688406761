import axios from 'axios';
import { isNaN, noop } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Captcha from 'react-google-recaptcha';
import {
  Button,
  Typography,
  TextField,
  Box,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Feedback, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, NavLink } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Select from '../../../components/select';
import ErrorSnackBar from '../../../components/snackBar/ErrorSnackBar';
import { getRequest, postRequest } from '../../../services';
import URL from '../../../constants/urls';
import { getRoleList } from '../../../formatter/commonBootstrap';
import REGEX_PATTERN from '../../../constants/regex';
import ERROR_MESSAGE from '../../../constants/errorMsgs';
import VALIDATION_VALUES from '../../../constants/validationValues';
import UI from '../../../constants/ui';
import URL_ID from '../../../constants/footerUrlIds';
import useQuery from '../../../hooks/useQuery';
import { getRecaptchaKey } from '../../../formatter/google.bootstrap';
import LOGIN_TYPE from '../../../constants/loginType';
import { EMPTY_ARRAY, DEFAULT_COMPANY_ID } from '../../../constants';
import { API_URL } from '../../../constants/apiUrls';
import SuggestNameDialog from '../../../components/suggestNameDialog/SuggestNameDialog';
import {
  setRequestsComplete,
  setRerenderpermissions,
  setHideLoginDialog,
  setContext,
} from '../../../../redux/userSlice';
import STATUS_CODE from '../../../constants/statusCode';
import { showSnackBar } from '../../../../redux/snackBarSlice';
import {
  getLoginDetailFromSession,
  setLoginDetailInSession,
} from '../../../helpers/sessionDetails';
import {
  getAvailablePointsUser,
  getNotificationCountUser,
} from '../../../../redux/apiCalls';
import useMobileDevice, {
  ismallMobileDevice,
} from '../../../hooks/useMobileDevice';
import { getLoginTypeFromSetting } from '../../../formatter/settings.bootstrap';
import Styles from './Register.module.css';
import { COMMON_LOGIN_STATE } from '../../../constants/loginStates.constants';
import CONTEXT_TYPE from '../../loginDialog/loginDialog.constants';
import AutoSuggestion from '../../../components/autoSuggestion';
import { extractSkillId, getIdFromName } from '../../../helpers/skillId';
import STORAGE_KEY from '../../../constants/storageKey';
import SocailMediaLogin from '../SocailMediaLogin';

export function requestDTO(formData, email, base64Encode) {
  if (base64Encode) {
    return { ...formData, email, password: base64Encode };
  }
  return { ...formData, email };
}
export function requestLoginDTO(formData, username, base64Encode) {
  return { ...formData, username, password: base64Encode };
}

function removeEmptyStrings(obj) {
  if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
    const result = {};
    Object.keys(obj).forEach((key) => {
      const value = removeEmptyStrings(obj[key]);
      if (value !== '') {
        result[key] = value;
      }
    });
    return result;
  }
  if (Array.isArray(obj)) {
    return obj.map(removeEmptyStrings);
  }

  return obj;
}

function Register(props) {
  const { setShowNavBar, setHideRegisterDialog } = props;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
    clearErrors,
    trigger,
  } = useForm({ mode: 'onChange' });

  const navigate = useNavigate();
  const loginTypeFromSettings = getLoginTypeFromSetting();
  const params = new URLSearchParams(window.location.search);
  const isOtpScreen = params.get('otp');
  const dispatch = useDispatch();
  const isLoginDialogOpen = useSelector((state) => state.user.showLoginDialog);
  const userName = useSelector((state) => state.user.username);
  const password = useSelector((state) => state.user.password);
  const context = useSelector((state) => state.user.context);
  const captchaRef = useRef(null);
  const [isCompanyChangeDisable, setisCompanyChangeDisable] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState();
  const [countdown, setCountdown] = useState(loginTypeFromSettings?.resendotp);
  const [opensnackbar, setSnackbarOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [registerData, setRegisterData] = useState();
  const [token, setToken] = useState();
  const [showOtpField, setShowOtpField] = useState(false);
  const [contextFromScreen, setContextFromScreen] = useState(0);
  const [isFresher, setIsFresher] = useState(false);
  const [spaceWarning, setSpaceWarning] = useState(false);
  const [isSkillWithRatingExists, setIsSkillWithRatingExists] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const isReferralCodeInURL = searchParams.get('referrer');
  const searchParamsName = searchParams.get('name');
  const searchParamsEmail = searchParams.get('email');
  const roleList = getRoleList();
  const query = useQuery();
  const referrerId = query.get('referrer');
  const getReferrIdFromSession = sessionStorage.getItem(
    STORAGE_KEY.REFERRER_ID
  );

  const base64ToText = (base64) => {
    const decodedString = atob(base64);
    return decodedString;
  };

  useEffect(() => {
    if (searchParamsEmail && searchParamsName) {
      setValue('email', base64ToText(searchParamsEmail));
      setValue('name', base64ToText(searchParamsName));
      setValue('role', roleList[0]?.id);
    }
  }, []);

  useEffect(() => {
    if (
      contextFromScreen === CONTEXT_TYPE.OTP_FORM ||
      context === CONTEXT_TYPE.OTP_FORM
    ) {
      setShowOtpField(true);
    } else {
      setShowOtpField(false);
    }
  }, [context, contextFromScreen]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen({ setopen: false });
  };

  const getSkillsPayload = () => {
    const role = watch('role');
    const skillArray = [];

    if (role !== LOGIN_TYPE.EMPLOYER) {
      const skills = getValues('skill') || [];

      for (let i = 0; i < skills.length; i += 1) {
        const id = getIdFromName(skills[i]);
        if (id) {
          skillArray.push({ id, name: skills[i] });
        }
      }
    }

    return skillArray;
  };

  const isSmallDevice = ismallMobileDevice();
  const isMobileDevice = useMobileDevice();
  const isSpotOnMockInterview = sessionStorage.getItem(
    STORAGE_KEY.IS_SPOT_ON_MOCK_INTERVIEWS
  );
  const role = watch('role');
  const onClickBtn = (data) => {
    setSpaceWarning(false);
    setRegisterData(data);
    const base64Encode = btoa(data.password);
    const email = data.email.toLowerCase();
    const captchaToken = captchaRef.current.getValue();
    const skillPayload = getSkillsPayload();
    const requestPayload = requestDTO(
      data,
      email,
      data?.password && base64Encode
    );
    setIsLoading(true);
    if (role !== LOGIN_TYPE.EMPLOYER) {
      delete requestPayload.company;
      delete requestPayload.companyid;
      delete requestPayload.skill;
      requestPayload.skills = skillPayload;
      if (isFresher) {
        requestPayload.jobexp = -1;
      }
    } else {
      delete requestPayload.fresher;
      requestPayload.jobexp = -1;
    }
    delete requestPayload.suggestedname;
    const output = removeEmptyStrings(requestPayload);
    postRequest(
      searchParamsEmail
        ? `/register/oauth-user?captcha=${captchaToken}${
            referrerId || getReferrIdFromSession
              ? `&referrer=${referrerId || getReferrIdFromSession}`
              : ''
          }`
        : `/register/user?captcha=${captchaToken}${
            referrerId ? `&referrer=${referrerId}` : ''
          }`,
      output
    )
      .then((res) => {
        setSnackbarOpen({
          setopen: true,
          message: res.msg,
          severity: 'success',
        });
        if (role === LOGIN_TYPE.CANDIDATE) {
          dispatch(setContext(CONTEXT_TYPE.OTP_FORM));
          setContextFromScreen(CONTEXT_TYPE.OTP_FORM);
        } else if (isLoginDialogOpen && role === LOGIN_TYPE.EMPLOYER) {
          dispatch(setHideLoginDialog());
          navigate(`${URL.VERIFY_EMAIL}?email=${requestPayload.email}`);
          window.location.reload();
        } else if (role === LOGIN_TYPE.EMPLOYER) {
          navigate(`${URL.VERIFY_EMAIL}?email=${requestPayload.email}`);
        }
      })
      .catch((error) => {
        setSnackbarOpen({
          setopen: true,
          message: error.msg,
          severity: 'error',
        });
      })
      .finally(() => {
        captchaRef.current.reset();
        setIsLoading(false);
      });
  };
  const afterLogin = async () => {
    const userDetail = getLoginDetailFromSession();
    if (userDetail.role === LOGIN_TYPE.CANDIDATE) {
      getAvailablePointsUser(dispatch);
      getNotificationCountUser(dispatch);
      dispatch(setRequestsComplete());
      dispatch(setRerenderpermissions());
    }
    if (isLoginDialogOpen === true) {
      setHideRegisterDialog(true);
      if (
        isSpotOnMockInterview &&
        userDetail?.state === COMMON_LOGIN_STATE.INACTIVE
      ) {
        dispatch(setHideLoginDialog());
        window.location.reload();
      } else {
        dispatch(setContext(CONTEXT_TYPE.CANDIDATE_FORM));
      }
    }
    if (
      isLoginDialogOpen === false &&
      userDetail.role === LOGIN_TYPE.CANDIDATE
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      navigate(URL.CANDIDATE_PROFILE);
    }
  };
  const handleLogin = () => {
    setIsLoading(true);
    getRequest(API_URL.CREDENTIAL)
      .then((resp) => {
        setLoginDetailInSession(resp);
        const loggedInUserRole = resp.role;
        if (loggedInUserRole === LOGIN_TYPE.CANDIDATE) {
          getRequest(API_URL.LAST_ASSESSMENT)
            .then((response) => {
              if (response?.showmsg) {
                dispatch(
                  showSnackBar({
                    setopen: true,
                    message: response?.msg,
                    severity: 'info',
                    duration: 10000,
                  })
                );
              }
            })
            .catch((error) => {
              setSnackbarOpen({
                setopen: true,
                message: error.msg,
                severity: 'error',
              });
            });
          afterLogin();
        }
      })
      .finally(() => setIsLoading(false));
  };

  const otpWatch = watch('otp');

  useEffect(() => {
    if (otpWatch) {
      setToken(otpWatch);
    }
  }, [otpWatch]);

  const handleLoginAPI = () => {
    const username = registerData?.email.toLowerCase() || userName;
    const base64Encode = btoa(registerData?.password || password);
    const requestPayload = requestLoginDTO(
      registerData,
      username,
      base64Encode
    );
    delete requestPayload.role;
    delete requestPayload.name;
    delete requestPayload.mobile;
    delete requestPayload.email;
    const requestPayloads = new URLSearchParams(requestPayload);
    setIsLoading(true);
    axios
      .post(API_URL.LOGIN, requestPayloads, {
        withCredentials: true,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        setSnackbarOpen({
          setopen: true,
          message: res?.data?.msg,
          severity: 'success',
        });
        if (res.data.code === STATUS_CODE.SUCCESSFULLY_LOGIN) {
          handleLogin();
        }
      })
      .catch((error) => {
        setSnackbarOpen({
          setopen: true,
          message: error.response?.data?.msg,
          severity: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleVerifyOtp = () => {
    setVerifyOtp(true);
    const email = registerData?.email.toLowerCase() || userName;
    const candidateUrl = `/register/verifyotp?otp=${token}&email=${email}`;
    const employerUrl = `/register/confirm?token=${token}`;
    const url =
      registerData?.role === LOGIN_TYPE.CANDIDATE ? candidateUrl : employerUrl;
    getRequest(url)
      .then((res) => {
        setVerifyOtp(false);
        if (!searchParamsEmail) {
          handleLoginAPI();
        }
        setSnackbarOpen({
          setopen: true,
          message: res.msg,
          severity: 'success',
        });
        if (res.code === STATUS_CODE.CONFIRM_TOKEN_SUCCESS) {
          setSnackbarOpen({
            setopen: true,
            message: res.msg,
            severity: 'success',
          });
          if (searchParamsEmail) {
            handleLogin();
          }
        }
      })
      .catch((error) => {
        if (
          error.code === STATUS_CODE.INVALID_TOKEN ||
          STATUS_CODE.EXPIRE_TOKEN
        ) {
          setSnackbarOpen({
            setopen: true,
            message: error.msg,
            severity: 'error',
          });
        }
      })
      .finally(() => {
        setVerifyOtp(false);
      });
  };

  useEffect(() => {
    let timer;
    if (showOtpField) {
      if (countdown > 0) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
      }
    }
    return () => {
      clearInterval(timer);
    };
  }, [countdown, showOtpField]);

  const handleResendClick = () => {
    setCountdown(loginTypeFromSettings?.resendotp);
    const email = registerData?.email.toLowerCase() || userName;
    getRequest(`/register/resendotp?email=${email}`)
      .then((res) => {
        setSnackbarOpen({
          setopen: true,
          message: res.msg,
          severity: 'success',
        });
      })
      .catch((error) => {
        setSnackbarOpen({
          setopen: true,
          message: error.msg,
          severity: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const recaptchaKey = getRecaptchaKey();
  const setCompanyWithId = (object) => {
    setValue('company', object?.label || object?.name);
    if (object?.label || object?.name) setisCompanyChangeDisable(true);
    setValue('companyid', object?.id || DEFAULT_COMPANY_ID);
  };
  const handleCompanyChange = () => {
    if (role !== LOGIN_TYPE.EMPLOYER) return;
    setisCompanyChangeDisable(false);
    const email = watch('email');
    if (email)
      getRequest(`${API_URL.AUTOCOMPLETE_COMPANY}?email=${email}`).then(
        (response) => {
          setCompanyWithId(response);
        }
      );
  };

  const [isSuggestDialogOpen, setSuggestDialogVisible] = useState(false);

  useEffect(() => {
    handleCompanyChange();
  }, [role]);

  useEffect(() => {
    setShowNavBar(true);
  }, EMPTY_ARRAY);

  useEffect(() => {
    if (isOtpScreen) {
      setShowOtpField(true);
    }
  }, [isOtpScreen]);

  const setSkill = (name) => {
    setSpaceWarning(false);
    if (!name || name.length === 0) return;

    const lastInput = name[name.length - 1]?.trim().toLowerCase();
    const updatedName = name.slice(0, name.length - 1);

    const isDuplicate = updatedName.some(
      (str) => str?.trim().toLowerCase() === lastInput
    );

    if (isDuplicate) {
      name.pop();
      setValue('skill', [...name]);
      setIsSkillWithRatingExists(true);
    } else {
      name?.forEach((str) => {
        if (extractSkillId(str?.trim()) === -1) {
          setSpaceWarning(true);
        } else {
          setSpaceWarning(false);
        }
      });

      setValue('skill', [...name]);
      setIsSkillWithRatingExists(false);
    }

    trigger('skill');
  };

  const renderFooterLine = () =>
    isLoginDialogOpen ? (
      <Typography sx={{ color: 'gray', mx: 4, mt: 1, textAlign: 'center' }}>
        {UI.ALREADY_ON_COMPANY_IN}&nbsp;
        <span
          style={{ color: '#1976d2', cursor: 'pointer' }}
          onClick={() => dispatch(setContext(CONTEXT_TYPE.LOGIN_FORM))}
        >
          {UI.LOGIN}
        </span>
      </Typography>
    ) : (
      <Typography sx={{ color: 'gray', mx: 4, mt: 1, textAlign: 'center' }}>
        {UI.ALREADY_ON_COMPANY_IN}{' '}
        <Link
          sx={{ color: '#1976d2' }}
          underline="none"
          as={NavLink}
          to={URL.LOGIN}
        >
          {UI.LOGIN}
        </Link>
      </Typography>
    );

  const onChangeFresher = (event) => {
    const isChecked = event.target.checked;
    setValue('fresher', isChecked, { shouldValidate: true });
    setValue('jobexp', undefined, { shouldValidate: true });
    setIsFresher(isChecked);
  };
  const renderRegisterFields = () => (
    <div
      style={{
        boxShadow: '0px 0px 3px #48B2FF29',
        backgroundColor: '#fff',
        borderRadius: '8px',
        maxWidth: isMobileDevice ? 350 : 650,
      }}
      className="card px-3 py-2 container"
    >
      <Typography align="left" fontSize="2rem" mb={4}>
        {UI.REGISTER}
      </Typography>
      <div className={isMobileDevice ? 'p-2' : 'col-md-6 p-2'}>
        <label>{UI.ROLE}</label>
        <Box component="span" sx={{ color: '#d32f2f' }}>
          *
        </Box>
        <Select
          inputId="role"
          name="role"
          control={control}
          options={getRoleList()}
          isRequired
          fullWidth
          id="role"
          defaultValue={UI.SELECT_OPTION}
          {...register('role')}
          className={Styles.smallField}
          disabled={searchParamsName || searchParamsEmail}
        />
        {errors.role && (
          <span className="mandatory">{errors.role.message}</span>
        )}
      </div>
      <div
        className={isMobileDevice ? 'd-flex flex-column' : 'd-flex flex-wrap'}
      >
        <div className={isMobileDevice ? 'px-2' : 'col-md-6 px-2'}>
          <label>{UI.EMAIL}</label>
          <Box component="span" sx={{ color: '#d32f2f' }}>
            *
          </Box>
          <div className="mb-2 mt-1">
            <TextField
              {...register('email', {
                required: ERROR_MESSAGE.REQ_ERROR_MSG,
                pattern: {
                  value: REGEX_PATTERN.EMAIL_FORMAT,
                  message: ERROR_MESSAGE.EMAIL_VALIDATION,
                },
                onChange: (event) => {
                  setValue('email', event.target.value.toLowerCase());
                },
              })}
              fullWidth
              type="email"
              id="email"
              variant="outlined"
              size="small"
              placeholder={UI.ENTER_EMAIL}
              name="email"
              onBlur={!errors.email && handleCompanyChange}
              className={Styles.smallField}
              disabled={searchParamsEmail}
            />
            {errors.email && (
              <Box>
                <span className="mandatory">{errors.email.message}</span>
              </Box>
            )}
          </div>
        </div>
        {!searchParamsEmail && !searchParamsName && (
          <div className={isMobileDevice ? 'px-2' : 'col-md-6 px-2'}>
            <div className="d-flex align-items-center">
              {UI.PASSWORD}
              <Tooltip title={ERROR_MESSAGE.PASSWORD_INFO}>
                <HelpOutlineIcon
                  sx={{ fontSize: 16, ms: 4 }}
                  color="disabled"
                />
              </Tooltip>
              <Box component="span" sx={{ color: '#d32f2f' }}>
                *
              </Box>
            </div>

            <div className="mb-2">
              <OutlinedInput
                fullWidth
                {...register('password', {
                  required: ERROR_MESSAGE.REQ_ERROR_MSG,
                  pattern: {
                    value: REGEX_PATTERN.PASSWORD_FORMAT,
                    message: ERROR_MESSAGE.PASSWORD_INFO,
                  },
                  maxLength: {
                    value: VALIDATION_VALUES.MAX_VALUE_16,
                    message:
                      ERROR_MESSAGE.MAX_ERROR_MSG +
                      VALIDATION_VALUES.MAX_VALUE_16,
                  },
                })}
                className={Styles.smallField}
                id="outlined-adornment-password"
                variant="outlined"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                placeholder={UI.ENTER_PASSWORD}
                size="small"
                name="password"
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment
                    position="end"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <Tooltip
                      title={
                        values.showPassword
                          ? UI.HIDE_PASSWORD
                          : UI.SHOW_PASSWORD
                      }
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              {errors.password && (
                <Box maxWidth={290}>
                  <span className="mandatory">{errors.password.message}</span>
                </Box>
              )}
            </div>
          </div>
        )}
        {role && role === LOGIN_TYPE.EMPLOYER && (
          <div className={isMobileDevice ? 'px-2' : 'col-md-6 px-2 mt-1'}>
            <label>{UI.COMPANY_NAME}</label>
            <Box component="span" sx={{ color: '#d32f2f' }}>
              *
            </Box>
            <OutlinedInput
              {...register('company', {
                required: ERROR_MESSAGE.REQ_ERROR_MSG,
              })}
              fullWidth
              type="text"
              id="company"
              variant="outlined"
              className={`${Styles.smallField} mb-2`}
              placeholder={UI.COMPANY_NAME}
              disabled={isCompanyChangeDisable}
              name="company"
              size="small"
              endAdornment={
                isCompanyChangeDisable &&
                !errors.email && (
                  <InputAdornment
                    position="end"
                    onClick={() => {
                      setSuggestDialogVisible(true);
                    }}
                  >
                    <Tooltip title={UI.SUGGEST_NAME}>
                      <IconButton edge="end">
                        <Feedback />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }
            />
          </div>
        )}
        <div className={isMobileDevice ? 'px-2' : 'col-md-6 px-2'}>
          <label>{UI.NAME}</label>
          <Box component="span" sx={{ color: '#d32f2f' }}>
            *
          </Box>
          <div className="mb-2 mt-1">
            <TextField
              {...register('name', {
                required: ERROR_MESSAGE.REQ_ERROR_MSG,
                pattern: {
                  value: REGEX_PATTERN.NAME_FORMAT,
                  message: ERROR_MESSAGE.NAME_VALIDATION_MESSAGE,
                },
              })}
              fullWidth
              type="text"
              id="name"
              variant="outlined"
              size="small"
              placeholder={UI.NAME}
              name="name"
              className={Styles.smallField}
              disabled={searchParamsName}
            />
            {errors.name && (
              <Box>
                <span className="mandatory">{errors.name.message}</span>
              </Box>
            )}
          </div>
        </div>

        <div
          className={
            isMobileDevice
              ? 'px-2'
              : searchParamsName
              ? 'col-md-12 px-2'
              : 'col-md-6 px-2'
          }
        >
          <label>{UI.PHONE_NUMBER}</label>
          <Box component="span" sx={{ color: '#d32f2f' }}>
            *
          </Box>
          <div className="mb-2 mt-1">
            <TextField
              {...register('mobile', {
                required: ERROR_MESSAGE.REQ_ERROR_MSG,
                pattern: {
                  value: REGEX_PATTERN.NUMERIC_FORMAT,
                  message: ERROR_MESSAGE.PHONE_VALIDATION_MESSAGE,
                },
                minLength: {
                  value: VALIDATION_VALUES.MIN_VALUE,
                  message:
                    ERROR_MESSAGE.MIN_ERROR_MSG + VALIDATION_VALUES.MIN_VALUE,
                },
                maxLength: {
                  value: VALIDATION_VALUES.MAX_VALUE_15,
                  message:
                    ERROR_MESSAGE.MAX_ERROR_MSG +
                    VALIDATION_VALUES.MAX_VALUE_15,
                },
              })}
              className={Styles.smallField}
              fullWidth
              type="text"
              size="small"
              id="number"
              inputProps={{
                maxLength: VALIDATION_VALUES.MAX_VALUE_15,
                minLength: VALIDATION_VALUES.MAX_VALUE_10,
              }}
              variant="outlined"
              placeholder={UI.ENTER_PHONE_NUMBER}
              name="mobile"
            />
            {errors.mobile && (
              <Box>
                <span className="mandatory">{errors.mobile.message}</span>
              </Box>
            )}
          </div>
        </div>
        {role && role === LOGIN_TYPE.CANDIDATE && (
          <div className={isMobileDevice ? 'px-2' : 'col-md-12 px-2'}>
            <div className="col-md-12">
              <label htmlFor="skill" className="form-label col-12">
                {UI.SKILLS}
                <Box component="span" sx={{ color: '#d32f2f' }}>
                  *
                </Box>
                <AutoSuggestion
                  id="skill"
                  name="skill"
                  values={getValues('skill')}
                  setValues={setSkill}
                  placeholder={ERROR_MESSAGE.SKILL_PLACEHOLDER}
                  control={control}
                  isSkillField
                  isMulti
                  onBlur={() => {
                    const enterEvent = new KeyboardEvent('keydown', {
                      key: 'Enter',
                      keyCode: 13,
                      which: 13,
                      bubbles: true,
                    });

                    document.getElementById('skill').dispatchEvent(enterEvent);
                  }}
                  {...register('skill', {
                    validate: (value) => {
                      if (!value || value.length === 0) {
                        return `${ERROR_MESSAGE.MIN_ERROR_MSG}${VALIDATION_VALUES.MIN_VALUE}`;
                      }
                      if (value.length > VALIDATION_VALUES.MAX_VALUE_64) {
                        return `${ERROR_MESSAGE.MAX_ERROR_MSG}${VALIDATION_VALUES.MAX_VALUE_64}`;
                      }
                      return true;
                    },
                  })}
                />
                {errors.skill ? (
                  <span className="mandatory">{errors.skill.message}</span>
                ) : isSkillWithRatingExists ? (
                  <span className="mandatory">
                    {ERROR_MESSAGE.DUPLICATE_SKILL_ERROR_MESSAGE}
                  </span>
                ) : (
                  spaceWarning && (
                    <span className="mandatory">
                      {ERROR_MESSAGE.SPACE_WARNING}
                    </span>
                  )
                )}
              </label>
            </div>
          </div>
        )}
        {role && role === LOGIN_TYPE.CANDIDATE && (
          <div className={isMobileDevice ? 'px-2' : 'col-md-12 px-2 mb-1'}>
            <span className="subtitle-1">Experience</span>
            <Box component="span" sx={{ color: '#d32f2f' }}>
              *
            </Box>
            <div
              className={`d-flex  mt-1 ${
                isMobileDevice ? 'flex-column' : 'flex-row align-items-center '
              }`}
            >
              <div
                className="form-check "
                style={{ width: isMobileDevice ? ' 100%' : '27%' }}
              >
                <label className="form-check-label" htmlFor="fresher">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="fresher"
                    {...register('fresher', {
                      onChange: (e) => {
                        onChangeFresher(e);
                        if (e.target.checked) {
                          setValue('jobexp', '');
                          clearErrors('jobexp');
                        }
                        trigger('jobexp');
                      },
                    })}
                  />
                  {UI.ARE_YOU_A_FRESHER}
                </label>
              </div>
              <label htmlFor="jobexp" style={{ width: '100%' }}>
                <input
                  id="jobexp"
                  type="number"
                  step="0.01"
                  min={VALIDATION_VALUES.ZERO_VALUE}
                  placeholder={UI.WORK_EXPERIENCE_IN_YEAR}
                  className="form-control"
                  {...register('jobexp', {
                    validate: (value) => {
                      if (!getValues('fresher')) {
                        const numericValue = parseFloat(value);
                        if (numericValue < VALIDATION_VALUES.DECIMAL_ONE) {
                          return `${ERROR_MESSAGE.MIN_VALUE_MSG}${VALIDATION_VALUES.DECIMAL_ONE}`;
                        }
                        if (value === undefined || value === '') {
                          return ERROR_MESSAGE.REQ_ERROR_MSG;
                        }
                      }
                      return true;
                    },
                    disabled: getValues('fresher'),
                  })}
                />
              </label>
            </div>
            {errors.jobexp && (
              <span className="mandatory my-1" style={{ float: 'right' }}>
                {errors.jobexp.message}
              </span>
            )}
          </div>
        )}
        {role && role === LOGIN_TYPE.CANDIDATE && (
          <div className="px-2 w-100 mb-2">
            <label htmlFor="linkedin" className="mt-1">
              {UI.LINKEDIN}
            </label>
            <TextField
              {...register('linkedin', {
                pattern: {
                  value: REGEX_PATTERN.NAME_FORMAT,
                  message: ERROR_MESSAGE.NAME_VALIDATION_MESSAGE,
                },
              })}
              fullWidth
              type="text"
              id="linkedin"
              variant="outlined"
              size="small"
              placeholder={UI.LINKEDIN_LINK}
              name="linkedin"
            />
            {errors.linkedin && (
              <Box>
                <span className="mandatory">{errors.linkedin.message}</span>
              </Box>
            )}
          </div>
        )}

        {role && !isReferralCodeInURL && (
          <div
            className={
              isMobileDevice ? 'px-2' : !role ? 'w-100 px-2 ' : 'col-md-6 px-2'
            }
          >
            <label htmlFor="refCode" className="mt-1">
              {UI.REFERRAL_CODE}
            </label>

            <TextField
              {...register('refCode', {
                maxLength: {
                  value: VALIDATION_VALUES.MAX_VALUE_10,
                  message:
                    ERROR_MESSAGE.MAX_ERROR_MSG +
                    VALIDATION_VALUES.MAX_VALUE_10,
                },
                minLength: {
                  value: VALIDATION_VALUES.MIN_VALUE,
                  message:
                    ERROR_MESSAGE.MAX_ERROR_MSG + VALIDATION_VALUES.MIN_VALUE,
                },
              })}
              fullWidth
              placeholder={UI.PLACEHOLDER_REFERRAL_CODE}
              id="refCode"
              size="small"
              error={!!errors.refCode?.message}
            />
            {errors.refCode && (
              <Box>
                <span className="mandatory">{errors.refCode.message}</span>
              </Box>
            )}
          </div>
        )}
      </div>
      <div className="px-2">
        <div style={{ marginTop: 14, marginBottom: 2 }}>
          <Captcha
            sitekey={recaptchaKey}
            ref={captchaRef}
            size={isSmallDevice ? 'compact' : 'normal'}
          />
        </div>
        <Typography
          align="center"
          sx={{
            fontSize: '10px',
            mt: 2,
            mb: 2,
            letterSpacing: '1px',
          }}
        >
          {UI.BY_CLICKING_AGREE_JOIN_YOU_AGREE_TO_THE_COMPANY_IN}{' '}
          <span className="color-1D8FF2 cursorPointer ms-1">
            <Link
              href={`${URL.INFORMATION}/${URL_ID.PRIVACY}`}
              underline="none"
              target="_blank"
            >
              {UI.PRIVACY_POLICY}
            </Link>
          </span>
          <br /> {UI.AND}{' '}
          <span className="color-1D8FF2 cursorPointer ms-1">
            <Link
              href={`${URL.INFORMATION}/${URL_ID.TERMS}`}
              underline="none"
              target="_blank"
            >
              {UI.TERM_AND_CONDITIONS}
            </Link>
          </span>
        </Typography>
        <Button
          fullWidth
          size="large"
          variant="contained"
          type="submit"
          sx={{ mb: 2 }}
          disabled={isLoading}
          startIcon={
            isLoading && <CircularProgress size="1rem" color="inherit" />
          }
          className={Styles.smallField}
        >
          {UI.REGISTER}
        </Button>
        {!searchParamsEmail && !searchParamsName && (
          <SocailMediaLogin isFromSignUp />
        )}
      </div>
    </div>
  );

  const renderOtpFiled = () => (
    <div className="card-border px-2 py-2">
      <div
        className=" d-flex flex-column align-items-center"
        style={{ maxWidth: '100%', padding: 1 }}
      >
        <Typography align="center" fontSize="1rem" mb={2}>
          {UI.ENTER_OTP_FROM_EMAIL}
        </Typography>
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <label htmlFor="otp" className="span-together">
              {UI.OTP}
              <span className="m-0">:</span>
            </label>
          </div>

          <div className="d-flex justify-content-start w-100 pe-5 ps-3 no_spineer">
            <TextField
              id="otp"
              type="number"
              placeholder={UI.OTP}
              variant="outlined"
              size="small"
              className={`form-control `}
              error={!!errors.otp}
              {...register('otp', {
                required: ERROR_MESSAGE.REQ_ERROR_MSG,
                validate: {
                  validValue: (value) => {
                    const numericValue = parseInt(value, 10);
                    if (isNaN(numericValue)) {
                      return ERROR_MESSAGE.NUMBER_VALIDATION_MESSAGE;
                    }
                    if (numericValue < 100000 || numericValue > 999999) {
                      return ERROR_MESSAGE.OTP_ERROR_MESSAGE;
                    }
                    return true;
                  },
                },
              })}
            />
          </div>
        </div>
        <span
          className={countdown === 0 ? '' : 'disabled'}
          onClick={countdown === 0 ? handleResendClick : undefined}
          style={{
            cursor: countdown === 0 ? 'pointer' : 'default',
            color: countdown === 0 ? 'blue' : 'grey',
            marginTop: 12,
            marginBottom: 8,
            fontSize: '12px',
          }}
        >
          {countdown === 0
            ? UI.RESEND_OTP
            : `${UI.RESEND_OTP_IN} ${countdown} ${UI.SECONDS}`}
        </span>
        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit(handleVerifyOtp)}
          className="m-2"
          startIcon={
            verifyOtp && <CircularProgress size="1rem" color="inherit" />
          }
        >
          {UI.VERIFY}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        <div className="mt-3">
          <form onSubmit={handleSubmit(onClickBtn)} className="container">
            {!showOtpField ? renderRegisterFields() : renderOtpFiled()}
          </form>
        </div>
        {!showOtpField && renderFooterLine()}

        <ErrorSnackBar opensnackbar={opensnackbar} handleClose={handleClose} />
      </div>
      <SuggestNameDialog
        isSuggestDialogOpen={isSuggestDialogOpen}
        setSuggestDialogVisible={setSuggestDialogVisible}
        email={watch('email')}
      />
    </div>
  );
}
Register.propTypes = {
  setShowNavBar: PropTypes.func,
  setHideRegisterDialog: PropTypes.func,
  showOTPDlgTitle: PropTypes.bool,
};
Register.defaultProps = {
  setShowNavBar: noop,
  setHideRegisterDialog: noop,
  showOTPDlgTitle: undefined,
};

export default Register;
