/* eslint-disable no-undef */
import { noop } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { EMPTY_ARRAY } from '../../../constants';
import handleLogin, { afterLogin } from '../login/LoginHelper';
import {
  getLoginDetailFromSession,
  setPlanDetails,
} from '../../../helpers/sessionDetails';
import {
  setRequestsComplete,
  setRerenderpermissions,
} from '../../../../redux/userSlice';
import { getRequest } from '../../../services';
import { getPricePlanDetails } from '../../../constants/apiUrls';
import { showSnackBar } from '../../../../redux/snackBarSlice';
import Loader from '../../../components/loader/Loader';

function OauthLogin(props) {
  const { setShowNavBar, setLoggedInUserRole } = props;
  const context = useSelector((state) => state.user.context);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = getLoginDetailFromSession();
  const isSpotOnMockInterview = useSelector(
    (state) => state.user.shouldSpotOnMockInterviewPage
  );
  const isLoginDialogOpen = useSelector((state) => state.user.showLoginDialog);

  function checkProjectProperty(resolve) {
    const intervalId = setInterval(() => {
      const loginDetail = getLoginDetailFromSession();
      if (loginDetail && loginDetail.project) {
        clearInterval(intervalId);
        resolve(dispatch(setRequestsComplete()));
        dispatch(setRerenderpermissions());
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }

  const getPlanDetails = (planid) => {
    getRequest(getPricePlanDetails(planid))
      .then((res) => {
        setPlanDetails(res);
      })
      .catch((err) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: err.msg,
            severity: 'info',
            duration: 10000,
          })
        );
      });
  };

  const handleEmployerLogin = () => {
    if (
      userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING ||
      userDetail?.state === COMMON_LOGIN_STATE.INACTIVE
    ) {
      if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING) {
        setTitleForApprovalDialog(UI.ACCOUNT_APPROVAL_PENDING);
        setContentForApprovalDialog(UI.ACCOUNT_APPROVAL_PENDING_MSG);
      } else {
        setTitleForApprovalDialog(UI.ACCOUNT_INACTIVE);
        setContentForApprovalDialog(UI.ACCOUNT_INACTIVE_MSG);
      }
      setIsApprovalDialogLoading(true);
      setIsApprovalDialogOpen(true);
      setIsApprovalDialogLoading(false);
    } else if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.REJECTED) {
      setIsRejectedDialogLoading(true);
      setContentForRejectedDialog(UI.ACCOUNT_REJECTED_MSG);
      setIsRejectedDialogOpen(true);
      setIsRejectedDialogLoading(false);
    } else {
      afterLogin({
        isLoginDialogOpen,
        isSpotOnMockInterview,
        navigate,
        checkProjectProperty,
        getPlanDetails,
        dispatch,
        context,
      });
    }
  };

  useEffect(() => {
    handleLogin({
      isLoginDialogOpen,
      setLoggedInUserRole,
      dispatch,
      isSpotOnMockInterview,
      checkProjectProperty,
      navigate,
      getPlanDetails,
      handleEmployerLogin,
      context,
    });
  }, []);

  useEffect(() => {
    setShowNavBar(true);
  }, EMPTY_ARRAY);

  return <Loader />;
}

OauthLogin.propTypes = {
  setShowNavBar: PropTypes.func,
  setLoggedInUserRole: PropTypes.func,
};

OauthLogin.defaultProps = {
  setShowNavBar: noop,
  setLoggedInUserRole: () => {},
};
export default OauthLogin;
