const GA = {
  LOGIN: ' logged in',
  LOGOUT: ' logged out',
  BUY_POINTS: 'Bought Points',
  UPDATED_PROFILE: 'Updated Profile',
  EVENT_BUTTON: {
    LOGIN: 'Login Button',
    NAVIGATION: 'Navbar Button',
    SEARCH: 'Search Button',
    DIALOG: 'Dialog Button',
    LOGOUT: 'Logout Button',
  },
  CANDIDATE_EVENT: {
    NEW: 'Candidate Reverted',
    UNLOCK: 'Candidate Unlocked',
    APPLIED: 'Candidate Applied',
    MATCHING: 'Candidate Matching',
    SHORTLISTED: 'Candidate Shortlisted',
    NOT_SUITABLE: 'Candidate Not Suitable',
    INTERVIEWED: 'Candidate Interviewed',
    REJECTED_BY_EMPLOYER: 'Rejected By Employer',
    HIRED: 'Candidate Hired',
    ACCEPTED_BY_CANDIDATE: 'Accepted By Candidate',
    REJECTED_BY_CANDIDATE: 'Rejected By Candidate',
    DELETED_BY_EMPLOYER: 'Deleted By Employer',
    DELETED_BY_CANDIDATE: 'Deleted By Candidate',
    DID_NOT_JOIN: 'Did Not Join',
    WARRANTY_CLAIMED: 'Warranty Claimed',
    WARRANTY_APPROVED: 'Warranty Approved',
    WARRANTY_REJECTED: 'Warranty Rejected',
    BLOCKED_BY_EMPLOYER: 'Offered By Employer',
    ASSIGNMENT_STARTED: 'Started Assignment',
  },
  JOB_EVENT: {
    NEW: 'Job Reverted',
    APPLIED: 'Job Applied',
    SHORTLISTED: 'Job Shortlisted',
    NOT_SUITABLE: 'Job Not Suitable',
    MATCHING: 'Job Matching',
    AUTO_APPLIED: 'Job AutoApplied',
    POSTED_JOB: 'Posted Job',
    REPOSTED_JOB: 'Reposted Job',
  },
  PROJECT_EVENTS: {
    TRANSFER_OWNERSHIP: 'Transfered Ownership',
    CREATED_PROJECT: 'Created Project',
    JOINED_PROJECT: 'Joined Project',
    LEFT_PROJECT: 'Left Project',
    LOAD_SELECT: 'Loaded Project',
    ADD_USER: 'Added User',
    REMOVE_USER: 'Removed User',
  },
};

export default GA;
