const LOGIN_TYPE = {
  CANDIDATE: 1,
  EMPLOYER: 2,
  UNIVERSITY: 5,
  ADMIN: 3,
};

export const CONSULTANT = 2;

export default LOGIN_TYPE;
