/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { EMPTY_ARRAY } from '../../constants';
import STORAGE_KEY from '../../constants/storageKey';
import { postRequest } from '../../services';
import { API_URL } from '../../constants/apiUrls';
import { showSnackBar } from '../../../redux/snackBarSlice';

function ErrorFallback({ error }) {
  const [time, setTime] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error && time) {
      postRequest(API_URL.COMMON_LOG_UI_ERROR, error).catch((err) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: err?.message,
            severity: 'error',
          })
        );
      });
    }
  }, [error, time]);

  useEffect(() => {
    if (
      sessionStorage.getItem(STORAGE_KEY.ERROR_FALLBACK_REDIRECT) == null ||
      JSON.parse(sessionStorage.getItem(STORAGE_KEY.ERROR_FALLBACK_REDIRECT))
    ) {
      const countdownInterval = setInterval(() => {
        setTime((seconds) => seconds - 1);
      }, 1000);

      const redirectTimeout = setTimeout(() => {
        window.location.replace('./');
      }, 10000);

      return () => {
        clearInterval(countdownInterval);
        clearTimeout(redirectTimeout);
      };
    }
  }, EMPTY_ARRAY);

  return (
    <div className="container mt-5" role="alert">
      <div>
        <p>Something went wrong:</p>
      </div>
      <div>
        <pre>Redirecting to homepage in {time} seconds</pre>
      </div>
      <div>
        <a href="./" style={{ textDecoration: 'none' }}>
          Align My Career
        </a>
      </div>
    </div>
  );
}

export default ErrorFallback;
