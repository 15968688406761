import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Campaign, Close, Info, Policy } from '@mui/icons-material';
import { EMPTY_OBJECT } from '../../constants';
import { API_URL } from '../../constants/apiUrls';
import BTNACTION from './bannerBtnactions';
import { getRequest, postRequest } from '../../services';
import { getRelativeTimeSmall } from '../../formatter/date';
import { showSnackBar } from '../../../redux/snackBarSlice';
import RemarkDialog from '../../pages/candidates/RemarkDialog';
import ActionMenu from '../actionMenu';
import useMobileDevice from '../../hooks/useMobileDevice';

function Banner(props) {
  const { data, isNotification, setHideNotification } = props;
  const [remark, setRemark] = useState('');
  const [remarkTitle, setRemarkTitle] = useState('');
  const [isRemarkDialogOpen, setIsRemarkDialogOpen] = useState(false);
  const [btnClicked, setBtnClicked] = useState(EMPTY_OBJECT);
  const [isActionCompleted, setIsActionCompleted] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const dispatch = useDispatch();
  const isMobileDevice = useMobileDevice();
  const navigate = useNavigate();
  const [hideBanner, setHideBanner] = useState(false);

  const removeBanner = (button) => {
    if (button.hide && !isNotification) {
      setHideBanner(true);
    }
  };
  const handleRemarkDialogClose = () => {
    setIsRemarkDialogOpen(false);
    setRemark('');
  };
  const handleRemarkDialogSubmit = () => {
    postRequest(`${btnClicked.action}notificationid=${data.id}`, { remark })
      .then((res) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: res?.msg,
            severity: 'success',
          })
        );
        setIsActionCompleted(true);
        removeBanner(btnClicked);
      })
      .catch((error) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: error.msg,
            severity: 'error',
          })
        );
      });
    handleRemarkDialogClose();
  };
  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const onNotificationClick = (event) => {
    event.stopPropagation();
    if (!data.read && !isRead)
      getRequest(`${API_URL.BANNER_READ}?notificationid=${data.id}`)
        .then(() => {
          setIsRead(true);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error.msg,
              severity: 'error',
            })
          );
        });
  };

  const onClickBtnAction = (button, event) => {
    setIsRead(true);
    if (!isActionCompleted) setBtnClicked(button);
    event.stopPropagation();
    if (button.type === BTNACTION.NAVIGATE) {
      onNotificationClick(event);
      if (button.newtab) window.open(button.action, '_blank');
      else navigate(button.action);
      if (button.hide) setIsActionCompleted(true);
      removeBanner(button);
    } else if (button.type === BTNACTION.GET_API_CALL) {
      getRequest(`${button.action}notificationid=${data.id}`)
        .then((res) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: res?.msg,
              severity: 'success',
            })
          );
          setIsActionCompleted(true);
          removeBanner(button);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error.msg,
              severity: 'error',
            })
          );
        });
    } else if (button.type === BTNACTION.POST_API_CALL) {
      if (button.remark) {
        setRemarkTitle(button.label);
        setIsRemarkDialogOpen(true);
        return;
      }
      postRequest(`${button.action}notificationid=${data.id}`)
        .then((res) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: res?.msg,
              severity: 'success',
            })
          );
          setIsActionCompleted(true);
          removeBanner(button);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error.msg,
              severity: 'error',
            })
          );
        });
    }
  };

  const onMenuAction = (button) => {
    getRequest(`${button.action}notificationid=${data.id}`)
      .then((res) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: res?.msg,
            severity: 'success',
          })
        );
        if (button.hide) setHideNotification(data.id);

        setIsRead(true);
      })
      .catch((error) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: error.msg,
            severity: 'error',
          })
        );
      });
  };

  const onDismiss = () => {
    if (isActionCompleted) {
      setHideBanner(true);
      return;
    }
    getRequest(`${API_URL.BANNER_READ}?notificationid=${data.id}`)
      .then(() => {
        setHideBanner(true);
      })
      .catch((error) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: error.msg,
            severity: 'error',
          })
        );
      });
  };

  return (
    <>
      <Grid
        container
        flexDirection="row"
        alignItems="center"
        className="me-3"
        hidden={hideBanner}
      >
        <Grid
          item
          xs={1}
          sm={1}
          md={0.5}
          alignSelf="stretch"
          sx={{
            border: '1px solid',
            borderColor: '#b6b5b5',
            backgroundColor: data.announcement
              ? 'green'
              : data.policy
              ? 'red'
              : 'primary.main',
          }}
          className="d-flex"
          style={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            border: 'none',
          }}
        >
          <Box className="col-12 align-items-center justify-content-center d-flex p-2">
            {data.announcement ? (
              <Campaign sx={{ color: 'white' }} />
            ) : data.policy ? (
              <Policy sx={{ color: 'white' }} />
            ) : (
              <Info sx={{ color: 'white' }} />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={11}
          sm={11}
          md={11.5}
          alignSelf="stretch"
          sx={{
            border: '1px solid',
            borderColor: isNotification
              ? '#b6b5b5'
              : data.announcement
              ? 'green'
              : data.policy
              ? 'red'
              : 'primary.main',
          }}
          className={`${
            !isNotification
              ? ''
              : data.read || isRead
              ? 'background-read  cursorPointer'
              : 'background-unread  cursorPointer'
          } d-flex py-2`}
          style={{
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <div
            // className="d-flex w-100 flex-row justify-content-between"
            className={`${
              isMobileDevice ? 'flex-column' : 'flex-row'
            } d-flex w-100 justify-content-between `}
            onClick={isNotification ? onNotificationClick : undefined}
          >
            <Box>
              <Typography className="ms-2">
                <strong>{data.title}</strong>
              </Typography>
              <Typography className="ms-2">{data.description}</Typography>
            </Box>
            <div className="d-flex justify-content-between">
              <Box className=" d-flex justify-content-end align-items-center">
                {!data.actionTaken && !isActionCompleted ? (
                  data?.actions?.map((button, index) => (
                    <Button
                      key={index}
                      className="m-2 z-index-5"
                      variant={isNotification ? 'contained' : 'outlined'}
                      onClick={(event) => onClickBtnAction(button, event)}
                    >
                      {button.label}
                    </Button>
                  ))
                ) : data?.policy ? (
                  <>
                    {data?.actions?.map(
                      (button, index) =>
                        !button.hide && (
                          <Button
                            key={index}
                            className="m-2 z-index-5"
                            variant={isNotification ? 'contained' : 'outlined'}
                            onClick={(event) => onClickBtnAction(button, event)}
                          >
                            {button.label}
                          </Button>
                        )
                    )}
                    <Button
                      className="m-2 z-index-5"
                      variant={isNotification ? 'contained' : 'outlined'}
                      disabled
                    >
                      {data.actionTaken || btnClicked.actionTaken}
                    </Button>
                  </>
                ) : (
                  <Button
                    className="m-2 z-index-5"
                    variant={isNotification ? 'contained' : 'outlined'}
                    disabled
                  >
                    {data.actionTaken || btnClicked.actionTaken}
                  </Button>
                )}
              </Box>
              <div className="d-flex align-items-center justify-content-end">
                <span className="border-radius caption color-1F2830 background-D7D7D7 ps-1 pe-1 me-1 ms-1 span-together ">
                  {getRelativeTimeSmall(new Date(data.creationtime))}
                </span>
                {isNotification ? (
                  <ActionMenu
                    isNotification
                    actions={data.menus}
                    onAction={onMenuAction}
                    setNotificationRead={setIsRead}
                    onNotificationClick={onNotificationClick}
                  />
                ) : (
                  data.dismiss && (
                    <IconButton onClick={onDismiss}>
                      <Close />
                    </IconButton>
                  )
                )}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <RemarkDialog
        isDialogOpen={isRemarkDialogOpen}
        onRemarkSubmit={handleRemarkDialogSubmit}
        onRemarkClose={handleRemarkDialogClose}
        remark={remark}
        onRemarkChange={handleRemarkChange}
        remarkTitle={remarkTitle}
      />
    </>
  );
}

Banner.propTypes = {
  data: PropTypes.object,
  isNotification: PropTypes.bool,
  setHideNotification: PropTypes.func,
};

Banner.defaultProps = {
  data: EMPTY_OBJECT,
  isNotification: false,
  setHideNotification: noop,
};

export default Banner;
