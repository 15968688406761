const ENUM_TYPE = {
  CANDIDATE_ENUM_MAPPING: 'candidateEnumMapping',
  EMPLOYER_ENUM_MAPPING: 'employerEnumMapping',
  COMMON_ENUM_MAPPING: 'commonEnumMapping',
  CANDIDATE_STATE_MACHINES: 'candidateStateMachines',
  EMPLOYER_STATE_MACHINES: 'employerStateMachines',
  PROFILE_PCT: 'profilepct',
  PAYTM: 'paytm',
  FOOTER: 'footer',
  REPORT: 'report',
  PROCTOR: 'proctor',
  GOOGLE: 'google',
  PERMISSIONS: 'permissions',
  PROJECT_ENUM_MAPPING: 'projectEnumMapping',
  SETTINGS: 'settings',
  STRUCTURE_DATA: 'structuredata',
  AI_MODEL: 'aimodel',
};
export default ENUM_TYPE;
