export const EMPLOYER_LOGIN_ESTATE = {
  APPROVED: 1,
  APPROVAL_PENDING: 2,
  REJECTED: 3,
};

export const COMMON_LOGIN_STATE = {
  ACTIVE: 1,
  INACTIVE: 2,
  DELETED: 3,
  BLOCKED: 101,
};
