import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Pagination, CircularProgress } from '@mui/material';
import { API_URL } from '../../constants/apiUrls';
import Banner from './Banner';
import Content from '../content';
import { getRequest } from '../../services';
import { showSnackBar } from '../../../redux/snackBarSlice';
import { setNotificationCountToZero } from '../../../redux/userSlice';
import {
  DEFAULT_SELECTED_PAGE,
  EMPTY_ARRAY,
  PAGINATION_ROWS,
} from '../../constants';
import { portalScrollTo } from '../../utils/scroll';
import UI from '../../constants/ui';
import useTabletDevices from '../../hooks/useTabletDevice';

function Banners(props) {
  const { isNotification } = props;
  const areRequestsComplete = useSelector(
    (state) => state.user.areRequestsComplete
  );
  const [selectedPage, setSelectedPage] = useState(DEFAULT_SELECTED_PAGE);
  const myRef = useRef();
  const [count, setCount] = useState(0);
  const pagination = (page) => {
    portalScrollTo(myRef);
    setSelectedPage(page);
  };
  const [data, setData] = useState(EMPTY_ARRAY);
  const [allNotificationsData, setAllNotificationsData] = useState(EMPTY_ARRAY);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hideNotification, setHideNotification] = useState('');
  const tabletDevice = useTabletDevices();

  useEffect(() => {
    if (isNotification) {
      dispatch(setNotificationCountToZero());
      setIsLoading(true);
      getRequest(API_URL.GET_NOTIFICATIONS)
        .then((response) => {
          setCount(response.length || 0);
          setAllNotificationsData(response);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error.msg,
              severity: 'error',
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    if (areRequestsComplete) {
      getRequest(API_URL.GET_BANNERS)
        .then((response) => {
          setCount(response.length || 0);
          setData(response);
        })
        .catch((error) => {
          dispatch(
            showSnackBar({
              setopen: true,
              message: error.msg,
              severity: 'error',
            })
          );
        });
    }
  }, [areRequestsComplete]);

  useEffect(() => {
    const slicedArray = allNotificationsData.slice(
      (selectedPage - 1) * PAGINATION_ROWS,
      (selectedPage - 1) * PAGINATION_ROWS + PAGINATION_ROWS
    );
    if (slicedArray.length === 0 && allNotificationsData.length !== 0) {
      setSelectedPage(selectedPage - 1);
      return;
    }
    setData(slicedArray);
    setCount(allNotificationsData.length);
  }, [allNotificationsData, selectedPage]);

  useEffect(() => {
    const filteredArray = allNotificationsData.filter(
      (notification) => hideNotification !== notification.id
    );
    setAllNotificationsData(filteredArray);
  }, [hideNotification]);

  return (
    // <Content condition={isNotification || (data && data?.length > 0)}>
    <div
      className={`${
        isNotification
          ? `${
              tabletDevice ? 'container-fluid' : 'container'
            } profile_form_box card-border`
          : `${
              tabletDevice ? 'container-fluid' : 'container'
            } p-0 z-index-5 h-auto w-100 subHeader`
      }`}
      ref={myRef}
    >
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ minHeight: '50px' }}
        >
          <CircularProgress size="2rem" />
        </div>
      ) : (
        isNotification &&
        (data.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h4 className="headline-4 text-center">{UI.NO_NOTIFICATIONS}</h4>
          </div>
        ) : (
          <div className="m-3 p-3 headline-4-bold">{UI.NOTIFICATIONS}</div>
        ))
      )}
      {data?.map((row, index) =>
        !isNotification && (row.read || row.accepted) ? null : (
          <div className="my-2">
            <Banner
              key={index}
              data={row}
              isNotification={isNotification}
              setHideNotification={setHideNotification}
            />
          </div>
        )
      )}
      <Content condition={isNotification && Math.ceil(count / 10) > 1}>
        <Pagination
          count={Math.ceil(count / 10)}
          showFirstButton
          showLastButton
          className="justify-content-center d-flex mt-3 caption color-1F2830"
          onChange={(_, page) => pagination(page)}
          page={selectedPage}
        />
      </Content>
    </div>
    // </Content>
  );
}

Banners.propTypes = {
  isNotification: PropTypes.bool,
};

Banners.defaultProps = {
  isNotification: false,
};

export default Banners;
