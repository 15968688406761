import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import VerticalTabs from './TabPanel';
import styles from './FAQs.module.css';
import UI from '../../constants/ui';
import { EMPTY_ARRAY } from '../../constants';

function FAQs(props) {
  const { setShowNavBar } = props;
  const [searchFaqs, setSearchFaqs] = useState('');

  useEffect(() => {
    setShowNavBar(true);
  }, EMPTY_ARRAY);

  function handleChange(e) {
    setSearchFaqs(e.target.value);
  }
  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <h1 style={{ color: 'rgb(52, 48, 48)' }}>{UI.TITLE_FAQS}</h1>
        <input
          className="form-control"
          type="search"
          placeholder="Search"
          aria-label="Search"
          onChange={handleChange}
        />
      </div>
      <VerticalTabs searchFaqs={searchFaqs} />
    </div>
  );
}

FAQs.propTypes = {
  setShowNavBar: PropTypes.func,
};

FAQs.defaultProps = {
  setShowNavBar: noop,
};

export default FAQs;
