import PropTypes from 'prop-types';
import DynamicTitle from '../../helpers/DynamicTitle';
import Error from '../../pages/error';
import UI from '../../constants/ui';

// this is used for routes which are to be accessed only BEFORE login like Register
function PublicRoute(props) {
  const { role, children, title, description } = props;
  DynamicTitle({ title, description });

  if (role) {
    return <Error />;
  }

  return children;
}

PublicRoute.propTypes = {
  role: PropTypes.number,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

PublicRoute.defaultProps = {
  role: undefined,
  title: UI.TITLE_ALIGNMYCAREER,
  description: UI.DESC_DEFAULT,
};

export default PublicRoute;
