import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Container, NativeSelect } from '@mui/material';
import Button from '@mui/material/Button';
import { DateRange } from 'react-date-range';
import CloseIcon from '@mui/icons-material/Close';
import { Lock } from '@mui/icons-material';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { getTransactionType } from '../../../formatter/commonBootstrap';
import {
  getFormattedNumber,
  getFormattedCurrency,
} from '../../../formatter/number';
import { transactionTypeColor } from '../../../helpers/colorCode';
import { CommonSkeleton } from '../../../components/skeleton';
import { DEFAULT_SELECTED_PAGE, EMPTY_ARRAY } from '../../../constants';
import UI from '../../../constants/ui';
import PaginationInfo from './PaginationInfo';
import { getFormattedDateTime, getDateInYYMMDD } from '../../../formatter/date';
import { setTransactionHistoryUser } from '../../../../redux/apiCalls';
import TRANSACTION_TYPE from '../../../constants/transactionType';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import hasPermission from '../../../hooks/hasPermission';
import { PERMISSIONS_ACTIONS } from '../../../formatter/permissions.bootstrap';

function MobileTransactionView(props) {
  const {
    setOpen,
    transaction,
    isTransactionSkeletonShow,
    open,
    selectedPage,
    transactionDataShow,
  } = props;

  const [selectedId, setSelectedId] = useState(0);
  const dispatch = useDispatch();
  const AllTransactionHistory = useSelector(
    (state) => state.user.userInfo.fixedTransactionHistory
  );

  const usePrev = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const prevSelectedId = usePrev(selectedId);
  const [filterReset, setFilterReset] = useState(false);
  const refOne = useRef(null);
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpenDateDialog(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', hideOnClickOutside, true);
  }, EMPTY_ARRAY);
  useEffect(() => {
    if (selectedId !== 0 && dateRange[0]?.startDate !== null) {
      const startDate = getDateInYYMMDD(dateRange[0].startDate);
      const endDate = getDateInYYMMDD(dateRange[0].endDate);
      const filteredTransaction = AllTransactionHistory.filter((row) => {
        const compareDate = getDateInYYMMDD(row.time);

        if (
          compareDate >= startDate &&
          compareDate <= endDate &&
          row.type === selectedId
        )
          return row;
        return false;
      });
      setTransactionHistoryUser(dispatch, filteredTransaction);
    } else if (
      filterReset ||
      (selectedId !== prevSelectedId && dateRange[0]?.startDate === null)
    ) {
      const filteredTransaction = AllTransactionHistory.filter((row) =>
        selectedId !== 0 ? row.type === selectedId : row
      );
      setTransactionHistoryUser(dispatch, filteredTransaction);
      setFilterReset(false);
    } else {
      const startDate = getDateInYYMMDD(dateRange[0].startDate);
      const endDate = getDateInYYMMDD(dateRange[0].endDate);
      const filteredTransaction = AllTransactionHistory.filter((row) => {
        const compareDate = getDateInYYMMDD(row.time);

        if (compareDate >= startDate && compareDate <= endDate) return row;
        return false;
      });

      setTransactionHistoryUser(dispatch, filteredTransaction);
    }
  }, [dateRange, selectedId]);

  const reRenderPermissionsCode = useSelector(
    (state) => state.user.reRenderPermissionsCode
  );

  const hasTransactionHistoryPermission = useMemo(
    () => hasPermission(PERMISSIONS_ACTIONS.TXN_HISTORY),
    [reRenderPermissionsCode]
  );

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow
            onClick={() => {
              if (hasTransactionHistoryPermission) setOpen(!open);
            }}
          >
            <TableCell>
              <span className="headline-6 color-5B5B5B">
                {UI.VIEW_TRANSACTION_HISTORY}
              </span>
            </TableCell>
            <TableCell align="right">
              {!hasTransactionHistoryPermission && <Lock />}
              <IconButton
                size="small"
                disabled={!hasTransactionHistoryPermission}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto">
                {isTransactionSkeletonShow ? (
                  <CommonSkeleton />
                ) : (
                  <Box>
                    <Box className="row-12 mt-3 d-flex align-items-center">
                      <span className="row-2 mb-1 ms-3">Filter:</span>
                      <Container className="d-flex justify-content-around">
                        <NativeSelect
                          value={selectedId}
                          onChange={(event) =>
                            setSelectedId(parseInt(event.target.value, 10))
                          }
                          className="font-inherit"
                        >
                          <option
                            name="All"
                            value={0}
                            defaultChecked="true"
                            type="span"
                          >
                            {UI.ALL}
                          </option>
                          <option
                            name={getTransactionType(TRANSACTION_TYPE.CREDIT)}
                            value={TRANSACTION_TYPE.CREDIT}
                          >
                            {getTransactionType(TRANSACTION_TYPE.CREDIT)}
                          </option>
                          <option
                            name={getTransactionType(TRANSACTION_TYPE.DEBIT)}
                            value={TRANSACTION_TYPE.DEBIT}
                          >
                            {getTransactionType(TRANSACTION_TYPE.DEBIT)}
                          </option>
                          <option
                            name={getTransactionType(TRANSACTION_TYPE.CASHBACK)}
                            value={TRANSACTION_TYPE.CASHBACK}
                          >
                            {getTransactionType(TRANSACTION_TYPE.CASHBACK)}
                          </option>
                        </NativeSelect>
                        <span>
                          <CalendarMonthRoundedIcon
                            fontSize="medium"
                            onClick={() => setOpenDateDialog(!openDateDialog)}
                            type="button"
                          />
                          <Button
                            onClick={() => {
                              setDateRange([
                                {
                                  startDate: null,
                                  endDate: new Date(),
                                  key: 'selection',
                                },
                              ]);
                              setSelectedId(0);
                              setFilterReset(true);
                            }}
                          >
                            <CloseIcon />
                          </Button>
                        </span>
                      </Container>
                    </Box>
                    <div ref={refOne}>
                      {openDateDialog && (
                        <DateRange
                          onChange={(item) => setDateRange([item.selection])}
                          editableDateInputs
                          moveRangeOnFirstSelection
                          ranges={dateRange}
                          months={1}
                          direction="vertical"
                          maxDate={new Date()}
                          startDatePlaceholder="Beginning"
                          endDatePlaceholder="Today"
                        />
                      )}
                    </div>
                    <PaginationInfo
                      totalCount={transaction.length}
                      currentPageCount={transactionDataShow.length}
                      selectedPage={selectedPage}
                      classNames="mt-3 mb-1"
                    />

                    <Table size="small">
                      <TableHead>
                        <TableRow
                          sx={{
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: 'none',
                            },
                          }}
                        >
                          <TableCell>
                            <span className="subtitle-2-bold color-000000">
                              {UI.DETAILS}
                            </span>
                          </TableCell>

                          <TableCell align="right">
                            <span className="subtitle-2-bold color-000000 word-wrap">
                              {`${UI.AMOUNT} (${UI.POINTS})`}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactionDataShow?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              [`& .${tableCellClasses.root}`]: {
                                borderBottom: 'none',
                              },
                            }}
                          >
                            <TableCell>
                              <span className={transactionTypeColor(row.type)}>
                                {getTransactionType(row.type)}
                              </span>
                              <div className="text-truncate">
                                {row?.description?.slice(0, 26)}
                              </div>
                              {getFormattedDateTime(row.time)}
                            </TableCell>

                            <TableCell align="center">
                              {`${getFormattedCurrency(
                                row.amount
                              )} (${getFormattedNumber(row.point)})`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MobileTransactionView.propTypes = {
  transaction: PropTypes.array,
  isTransactionSkeletonShow: PropTypes.bool,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  selectedPage: PropTypes.number,
  transactionDataShow: PropTypes.array,
};
MobileTransactionView.defaultProps = {
  transaction: EMPTY_ARRAY,
  isTransactionSkeletonShow: false,
  setOpen: noop,
  open: false,
  selectedPage: DEFAULT_SELECTED_PAGE,
  transactionDataShow: EMPTY_ARRAY,
};

export default MobileTransactionView;
