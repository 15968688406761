const ASSESSMENT_STATE = {
  CREATED: 1,
  STARTED: 2,
  COMPLETED: 3,
  TERMINATED: 4,
  EXPIRED: 5,
  PENDING: 6,
  ERROR: 7,
};

export const ASSESSMENT_START_ID = 100000;

export default ASSESSMENT_STATE;
