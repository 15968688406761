import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PointsSummary from './PointsSummary';
import BuyCreditPointsDialog from './buyPoints';
import { EMPTY_OBJECT } from '../../constants';
import { getAvailablePointsUser } from '../../../redux/apiCalls';
import {
  getLoginDetailFromSession,
  getPlanDetails,
} from '../../helpers/sessionDetails';
import UI from '../../constants/ui';
import {
  BUTTON_TYPE,
  PAY_PER_USER_PLANID,
  PlanType,
} from '../../constants/pricingDashboard';
import { setButtonActionWhilePayment } from '../../../redux/userSlice';

function Payment() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shouldShowBuyPoints, setShouldShowBuyPoints] = useState(false);
  const [isNewPointUpdated, setIsNewPointUpdated] = useState(false);
  const planDetails = getPlanDetails();
  const dispatch = useDispatch();
  const loginDetails = getLoginDetailFromSession();
  const buttonType = useSelector(
    (state) => state.user.buttonActionWhilePayment
  );

  useEffect(() => {
    getAvailablePointsUser(dispatch);
  }, EMPTY_OBJECT);

  useEffect(() => {
    if (buttonType === BUTTON_TYPE.ADD_POINTS) {
      if (loginDetails?.planid !== PAY_PER_USER_PLANID) {
        setShouldShowBuyPoints(true);
      } else {
        setIsDialogOpen(true);
      }
    } else if (buttonType === BUTTON_TYPE.RENEW) {
      setIsDialogOpen(true);
    }
  }, [buttonType]);

  const handleOpenBuyPointDialog = () => {
    setIsDialogOpen(true);
    setIsNewPointUpdated(false);
  };

  const handleCloseBuyPointDialog = () => {
    setIsDialogOpen(false);
    dispatch(setButtonActionWhilePayment(0));
  };

  const handleSuccessTransaction = () => {
    setIsNewPointUpdated(true);
    setIsDialogOpen(false);
  };

  const handleRenewPlan = () => {
    const price =
      loginDetails?.plantype === PlanType.MONTHLY
        ? planDetails?.price?.value
        : planDetails?.price?.annualv;
    const point =
      loginDetails?.plantype === PlanType.MONTHLY
        ? planDetails?.points?.value
        : planDetails?.points?.annualv;
    if (buttonType === BUTTON_TYPE.RENEW) {
      return {
        amount: price,
        points: point,
        title: UI.BUY_RENEW_PLAN,
        planid: loginDetails?.planid,
        plantype: loginDetails?.plantype,
      };
    }

    return {};
  };

  return (
    <>
      {isDialogOpen && (
        <BuyCreditPointsDialog
          isDialogOpen={isDialogOpen}
          onCloseBuyPointDialog={handleCloseBuyPointDialog}
          onSuccessTransaction={handleSuccessTransaction}
          amountToBeReNew={handleRenewPlan()}
        />
      )}

      <PointsSummary
        onOpenBuyPointDialog={handleOpenBuyPointDialog}
        isNewPointUpdated={isNewPointUpdated}
      />
      <Dialog
        open={shouldShowBuyPoints}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography component="span" sx={{ fontSize: '1.25rem' }}>
            {UI.POINTS}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {UI.BUY_POINTS_MESSAGE}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setShouldShowBuyPoints(false);
              dispatch(setButtonActionWhilePayment(0));
            }}
          >
            {UI.CANCEL}
          </Button>
          <Button
            onClick={() => {
              setIsDialogOpen(true);
              setShouldShowBuyPoints(false);
            }}
            variant="contained"
          >
            {UI.OK}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Payment;
