import React from 'react';
import { useSelector } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import URL from '../../constants/urls';
import useMobileDevice from '../../hooks/useMobileDevice';

export default function NotificationIcon() {
  const notificationCount = useSelector(
    (state) => state.user.userInfo.notificationsCount
  );
  const isMobileDevice = useMobileDevice();
  const navigate = useNavigate();

  return isMobileDevice ? (
    <IconButton size="small" onClick={() => navigate(URL.NOTIFICATIONS)}>
      <Badge
        className="bold-child-span"
        badgeContent={notificationCount}
        max={99}
        color="error"
      >
        <NotificationsIcon color={`${notificationCount !== 0 && 'primary'}`} />
      </Badge>
    </IconButton>
  ) : (
    <IconButton size="large" onClick={() => navigate(URL.NOTIFICATIONS)}>
      <Badge
        className="bold-child-span"
        badgeContent={notificationCount}
        max={999}
        color="error"
      >
        <NotificationsIcon color={`${notificationCount !== 0 && 'primary'}`} />
      </Badge>
    </IconButton>
  );
}
