import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getRequest } from '../../../app/services';
import { MAINPAGE_API_URL } from '../../../app/constants/apiUrls';
import UI from '../../../app/constants/ui';
import URL from '../../../app/constants/urls';

function Feature({ role }) {
  const [featureone, setFeatureone] = useState('');
  const [featuretwo, setFeaturetwo] = useState('');
  const fetchFeatureOneData = () => {
    getRequest(`${MAINPAGE_API_URL.MAINPAGE_FEATURE_1}${role}`).then((data) => {
      setFeatureone(data);
    });
  };
  const fetchFeatureTwoData = () => {
    getRequest(`${MAINPAGE_API_URL.MAINPAGE_FEATURE_2}${role}`).then((data) => {
      setFeaturetwo(data);
    });
  };

  useEffect(() => {
    if (!role) return;
    fetchFeatureOneData();
    fetchFeatureTwoData();
  }, [role]);

  return (
    <div className="full-body-tab">
      <div className="section card_section">
        <div className="container">
          <div className="section-heading centred">
            <h2 className="section_head">
              Assess, Prepare, Upskill and Apply for Jobs on
              <br /> One Platform
            </h2>
          </div>

          <div className="card_wrap">
            <div className="row">
              <div className="col-lg-6">
                <div className="card card_one">
                  {featureone.title && (
                    <h3 className="section_head">{featureone.title}</h3>
                  )}
                  <p>{featureone.description}</p>
                  <Link
                    to={URL.ASSESSMENT}
                    rel="noopener noreferrer"
                    className="white-btn"
                  >
                    {UI.EXPLORE_ASSESSMENTS}
                    <span /> <span /> <span /> <span />
                  </Link>
                  <div className="card_img mt-2">
                    <img
                      src={featureone.image}
                      alt={UI.ALT_FEATURE_IMAGE_LEFT}
                      width="100%"
                      height="100%"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card_two">
                  <div className="card_img">
                    <img
                      src={featuretwo.image}
                      alt={UI.ALT_FEATURE_IMAGE_RIGHT}
                      width="100%"
                      height="100%"
                      loading="lazy"
                    />
                  </div>
                  {featuretwo.title && (
                    <h3 className="section_head">{featuretwo.title}</h3>
                  )}
                  <p>{featuretwo.description}</p>
                  <Link
                    to={URL.MOCK_INTERVIEW}
                    rel="noopener noreferrer"
                    className="white-btn"
                  >
                    {UI.EXPLORE_MOCK_INTERVIEW}
                    <span /> <span /> <span /> <span />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
Feature.propTypes = {
  role: PropTypes.number.isRequired,
};
export default Feature;
