import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import useMobileDevice from '../../hooks/useMobileDevice';
import ERROR_MESSAGE from '../../constants/errorMsgs';
import { getRequest } from '../../services';
import { API_URL } from '../../constants/apiUrls';
import { showSnackBar } from '../../../redux/snackBarSlice';
import UI from '../../constants/ui';
import VALIDATION_VALUES from '../../constants/validationValues';

function SuggestNameDialog(props) {
  const { email, isSuggestDialogOpen, setSuggestDialogVisible } = props;

  const {
    register,
    formState: { isValid, errors },
    unregister,
    setError,
    watch,
  } = useForm({ mode: 'all' });

  const isMobileDevice = useMobileDevice();
  const handleDialogClose = () => {
    setSuggestDialogVisible(false);
    unregister('suggestedname');
  };
  const dispatch = useDispatch();
  const handleActionBtn = () => {
    const suggestedname = watch('suggestedname');
    if (suggestedname === '') {
      setError(
        'suggestedname',
        { type: 'custom', message: ERROR_MESSAGE.REQ_ERROR_MSG },
        { shouldFocus: true }
      );
      return;
    }
    setError('suggestedname', false);
    getRequest(`${API_URL.SUGGEST_NAME}?email=${email}&name=${suggestedname}`)
      .then((res) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: res.msg,
            severity: 'success',
          })
        );
      })
      .catch((error) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: error.msg,
            severity: 'error',
          })
        );
      })
      .finally(() => handleDialogClose());
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobileDevice}
      open={isSuggestDialogOpen}
      onClose={handleDialogClose}
      sx={{ overflow: 'visible' }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography component="span" sx={{ fontSize: '1.25rem' }}>
          {UI.SUGGEST_NAME}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 14,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CancelOutlined />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers component="div">
        <div className="heading-4">{UI.SUGGEST_NAME_MSG}</div>
        <div className="d-flex align-items-center mt-2  me-2">
          <label htmlFor="suggestedname" className="span-together">
            {UI.NEW_NAME}

            <Box component="span" sx={{ color: '#d32f2f' }}>
              *
            </Box>

            <span className="m-2">:</span>
          </label>
          <div className="d-flex justify-content-center w-100">
            <TextField
              id="suggestedname"
              type="text"
              placeholder={UI.COMPANY_NAME}
              className={`form-control w-100 `}
              {...register('suggestedname', {
                minLength: {
                  value: VALIDATION_VALUES.MIN_VALUE,
                  message:
                    ERROR_MESSAGE.MIN_ERROR_MSG + VALIDATION_VALUES.MIN_VALUE,
                },
                maxLength: {
                  value: VALIDATION_VALUES.MAX_VALUE_64,
                  message:
                    ERROR_MESSAGE.MAX_ERROR_MSG +
                    VALIDATION_VALUES.MAX_VALUE_64,
                },
              })}
            />
          </div>
        </div>
        {errors.suggestedname && (
          <div
            className="field_space"
            style={{
              textAlign: 'right',
              paddingRight: '2%',
            }}
          >
            <span className="mandatory">{errors.suggestedname.message}</span>
          </div>
        )}
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.30)',
            marginTop: '1rem',
            marginBottom: '0',
          }}
        >
          {UI.SUGGEST_NAME_NOTE}
        </p>
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          onClick={(e) => {
            handleDialogClose(e);
          }}
        >
          {UI.CANCEL}
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={handleActionBtn}
          disabled={!isValid}
        >
          {UI.SUBMIT}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SuggestNameDialog.propTypes = {
  isSuggestDialogOpen: PropTypes.bool,
  setSuggestDialogVisible: PropTypes.func,
  email: PropTypes.string,
};

SuggestNameDialog.defaultProps = {
  isSuggestDialogOpen: false,
  setSuggestDialogVisible: noop,
  email: null,
};

export default SuggestNameDialog;
