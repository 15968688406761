import { EMPTY_OBJECT } from '../constants';
import { COMMON_LOGIN_STATE } from '../constants/loginStates.constants';
import LOGIN_TYPE from '../constants/loginType';
import URL from '../constants/urls';

function getRedirectUrl(currentUrl) {
  const { pathname, search } = window.location || EMPTY_OBJECT;
  return `${currentUrl}?redirectTo=${encodeURIComponent(pathname + search)}`;
}

export const getUrlAndRedirect = (currentUrl) => {
  const redirectUrl = getRedirectUrl(currentUrl);
  window.location.href = redirectUrl;
};

export const getDefaultUrlBasedOnRoleAndState = (
  loggedInUserRole,
  state,
  isSpotOnMockInterview
) => {
  let navigateUrl = URL.LOGIN;
  if (loggedInUserRole === LOGIN_TYPE.UNIVERSITY) {
    navigateUrl = URL.UNIV_STUDENTS;
  } else if (loggedInUserRole === LOGIN_TYPE.CANDIDATE) {
    if (state === COMMON_LOGIN_STATE.INACTIVE) {
      if (isSpotOnMockInterview) {
        navigateUrl = URL.MOCK_INTERVIEW;
      } else {
        navigateUrl = URL.CANDIDATE_PROFILE;
      }
    } else {
      navigateUrl = URL.FIND_JOBS;
    }
  } else if (loggedInUserRole === LOGIN_TYPE.EMPLOYER) {
    if (state === COMMON_LOGIN_STATE.INACTIVE) {
      navigateUrl = URL.EMPLOYER_PROFILE;
    } else {
      navigateUrl = URL.FIND_CANDIDATES;
    }
  } else if (loggedInUserRole === LOGIN_TYPE.ADMIN) {
    navigateUrl = `${URL.ADMIN_ACTION}?action=site_map`;
  }

  return navigateUrl;
};

export const getDefaultHomeUrl = (loggedInUserRole) => {
  if (loggedInUserRole === LOGIN_TYPE.EMPLOYER) {
    return URL.FIND_CANDIDATES;
  }
  return URL.FIND_JOBS;
};

export default getRedirectUrl;
