import React from 'react';
import PropTypes from 'prop-types';
import UI from '../../../constants/ui';
import {
  DEFAULT_SELECTED_PAGE,
  PAGINATION_ROWS,
  PAGINATION_ROWS_12,
} from '../../../constants';

function PaginationInfo(props) {
  const {
    totalCount,
    selectedPage,
    currentPageCount,
    classNames,
    isMockInterviewPage,
  } = props;
  const rows = isMockInterviewPage ? PAGINATION_ROWS_12 : PAGINATION_ROWS;

  return (
    <div className={`caption ${classNames}`}>
      {UI.SHOWING} {(selectedPage - 1) * rows + 1} -
      {(selectedPage - 1) * rows + 1 + currentPageCount - 1} {UI.OF}{' '}
      {totalCount} {UI.RESULTS}
    </div>
  );
}

PaginationInfo.propTypes = {
  totalCount: PropTypes.number,
  currentPageCount: PropTypes.number,
  selectedPage: PropTypes.number,
  classNames: PropTypes.string,
  isMockInterviewPage: PropTypes.bool,
};

PaginationInfo.defaultProps = {
  totalCount: 0,
  currentPageCount: 0,
  selectedPage: DEFAULT_SELECTED_PAGE,
  classNames: '',
  isMockInterviewPage: false,
};

export default PaginationInfo;
