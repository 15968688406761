import { getSkillList } from '../formatter/commonBootstrap';
import { DEFAULT_SKILL_ID } from '../constants';

export const getSkill = (skill) =>
  getSkillList().find(
    (skillId) => skillId.value.toLowerCase() === skill?.toLowerCase()
  );

export function extractSkillId(skill) {
  return getSkill(skill)?.id || DEFAULT_SKILL_ID;
}

export function extractSkillName(skill) {
  return getSkill(skill)?.value || skill;
}

export function getNameFromId(id) {
  const skill = getSkillList().find((eachItem) => eachItem.id === id);
  return skill ? skill.value : id;
}

export function getIdFromName(value) {
  const skill = getSkillList().find((eachItem) => eachItem.value === value);
  return skill ? skill.id : DEFAULT_SKILL_ID;
}
