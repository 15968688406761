import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes, { node } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BasicAccordion from './BasicAccordion';
import useMobileDevice from '../../hooks/useMobileDevice';
import { API_URL } from '../../constants/apiUrls';
import { getRequest } from '../../services';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.defaultProps = {
  children: node,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs(props) {
  const { searchFaqs } = props;
  const [value, setValue] = useState(0);
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    getRequest(API_URL.FAQS).then((result) => {
      setFaqs(result);
    });
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobileDevice = useMobileDevice();
  const showAllFaqs = true;
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: '90vh',
        overflowY: 'scroll',
        flexDirection: isMobileDevice ? 'column' : 'row',
      }}
    >
      <Tabs
        orientation={isMobileDevice ? 'horizontal' : 'vertical'}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="FAQs"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="All" {...a11yProps(0)} />
        <Tab label="Common" {...a11yProps(1)} />
        <Tab label="Candidate" {...a11yProps(2)} />
        <Tab label="Employer" {...a11yProps(3)} />
        <Tab label="University" {...a11yProps(4)} />
      </Tabs>
      <div style={{ width: '100%' }}>
        <TabPanel value={value} index={0}>
          <BasicAccordion
            faqs={faqs}
            showAllFaqs={showAllFaqs}
            searchFaqs={searchFaqs}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BasicAccordion category={1} faqs={faqs} searchFaqs={searchFaqs} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BasicAccordion category={2} faqs={faqs} searchFaqs={searchFaqs} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BasicAccordion category={3} faqs={faqs} searchFaqs={searchFaqs} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <BasicAccordion category={4} faqs={faqs} searchFaqs={searchFaqs} />
        </TabPanel>
      </div>
    </Box>
  );
}
VerticalTabs.propTypes = {
  searchFaqs: PropTypes.string,
};
VerticalTabs.defaultProps = {
  searchFaqs: '',
};
export default VerticalTabs;
