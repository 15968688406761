import ASSESSMENT_STATE from '../constants/assessmentState';
import RATING from '../constants/rating';
import TRANSACTION_TYPE from '../constants/transactionType';

const TRANSACTION_TYPE_COLOR = {
  [TRANSACTION_TYPE.CREDIT]: 'color-3DC996',
  [TRANSACTION_TYPE.DEBIT]: 'color-F25C05',
  [TRANSACTION_TYPE.CASHBACK]: 'color-1D8FF2',
};

export const transactionTypeColor = (transactionType) =>
  TRANSACTION_TYPE_COLOR[transactionType];

const RATING_COLOR = {
  [RATING.DEFAULT]: 'color-AAAAAA',
  [RATING.BEGINNER]: 'color-AD2DB5',
  [RATING.INTERMEDIATE]: 'color-FEAF18',
  [RATING.PROFICIENT]: 'color-35CDB0',
  [RATING.EXPERT]: 'color-F25C05',
};

const ratingColor = (rating) =>
  rating === ASSESSMENT_STATE.ERROR
    ? 'color-880808'
    : rating === ASSESSMENT_STATE.PENDING
    ? 'color-#e6b800'
    : RATING_COLOR[rating] || 'color-AAAAAA';

export default ratingColor;
