import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getDefaultUrlBasedOnRoleAndState } from '../../helpers/getRedirectUrl';
import { getLoginDetailFromSession } from '../../helpers/sessionDetails';
import UI from '../../constants/ui';
import DynamicTitle from '../../helpers/DynamicTitle';
import { showSnackBar } from '../../../redux/snackBarSlice';
import ERROR_MESSAGE from '../../constants/errorMsgs';

// this is used for routes which are to be accessed both BEFORE/AFTER login like find jobs/candidates
function ProtectedRoute(props) {
  const { role, roleTypes, children, title, description } = props;
  const dispatch = useDispatch();
  DynamicTitle({ title, description });

  if (role && !roleTypes.includes(role)) {
    const state = getLoginDetailFromSession()?.state;
    const navigateUrl = getDefaultUrlBasedOnRoleAndState(role, state);
    dispatch(
      showSnackBar({
        setopen: true,
        message: ERROR_MESSAGE.YOU_CANT_PERFROM,
        severity: 'error',
      })
    );
    return <Navigate to={navigateUrl} replace />;
  }

  return children;
}

ProtectedRoute.propTypes = {
  role: PropTypes.number,
  roleTypes: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

ProtectedRoute.defaultProps = {
  role: undefined,
  title: UI.TITLE_ALIGNMYCAREER,
  description: UI.DESC_DEFAULT,
};

export default ProtectedRoute;
