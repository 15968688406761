import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import URL from '../../constants/urls';
import getRedirectUrl, {
  getDefaultUrlBasedOnRoleAndState,
} from '../../helpers/getRedirectUrl';
import STORAGE_KEY from '../../constants/storageKey';
import { getLoginDetailFromSession } from '../../helpers/sessionDetails';
import DynamicTitle from '../../helpers/DynamicTitle';
import UI from '../../constants/ui';

// this is used for routes which are to be accessed only AFTER login like Profile
function PrivateRoute(props) {
  const { role, roleTypes, children, title, description } = props;
  DynamicTitle({ title, description });
  if (role && !roleTypes.includes(role)) {
    const state = getLoginDetailFromSession()?.state;
    const navigateUrl = getDefaultUrlBasedOnRoleAndState(role, state);
    return <Navigate to={navigateUrl} replace />;
  }

  if (!role || !roleTypes.includes(role)) {
    const redirectUrl = getRedirectUrl(URL.LOGIN);
    // TODO: Better
    localStorage.removeItem(STORAGE_KEY.SESSION_DETAILS);
    window.dispatchEvent(new Event('storage'));
    return <Navigate to={redirectUrl} replace />;
  }

  return children;
}

PrivateRoute.propTypes = {
  role: PropTypes.number,
  roleTypes: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

PrivateRoute.defaultProps = {
  role: undefined,
  title: UI.TITLE_ALIGNMYCAREER,
  description: UI.DESC_DEFAULT,
};

export default PrivateRoute;
