import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from '@mui/material';
import React from 'react';
import GithubSVG from '../../components/svg/GithubSVG';
import UI from '../../constants/ui';
// import LinkedInSVG from '../../components/svg/LinkedInSVG';
import GoogleSVG from '../../components/svg/GoogleSVG';
import { ismallMobileDevice } from '../../hooks/useMobileDevice';
import STORAGE_KEY from '../../constants/storageKey';
import useQuery from '../../hooks/useQuery';

function SocialMediaButton({ SvgIcon, text, color = '#6c757d', onClick }) {
  return (
    <Button
      variant="outlined"
      className="card "
      style={{
        boxShadow: '0px 0px 3px #48B2FF29',
        backgroundColor: '#fff',
        borderRadius: '8px',
        borderColor: color,
        color,
        padding: 6,
      }}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between">
        <SvgIcon />
        <div className="ms-2">{text}</div>
      </div>
    </Button>
  );
}

SocialMediaButton.propTypes = {
  SvgIcon: PropTypes.elementType.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

SocialMediaButton.defaultProps = {
  color: '#6c757d',
  onClick: noop,
};

function SocailMediaLogin(props) {
  const { isFromSignUp } = props;
  const isSmallDevice = ismallMobileDevice();
  const query = useQuery();
  const referrerId = query.get('referrer');

  const setReferrIdInSession = () => {
    if (referrerId) {
      sessionStorage.setItem(STORAGE_KEY.REFERRER_ID, referrerId);
    }
  };

  const googleLogin = () => {
    if (isFromSignUp) {
      setReferrIdInSession();
    }
    window.location.href = `${window.origin}/oauth2/authorization/google`;
  };

  const githubLogin = () => {
    if (isFromSignUp) {
      setReferrIdInSession();
    }
    window.location.href = `${window.origin}/oauth2/authorization/github`;
  };

  return (
    <>
      <div className="d-flex align-items-center py-2">
        <hr style={{ width: '100%', margin: 0 }} />
        <span className="mx-3" style={{ color: '#6c757d' }}>
          OR
        </span>
        <hr style={{ width: '100%', margin: 0 }} />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            isFromSignUp && !isSmallDevice ? 'auto auto' : 'auto auto',
          rowGap: 8,
          columnGap: 12,
          padding: isFromSignUp && '10px 25px 10px 25px',
        }}
      >
        <SocialMediaButton
          SvgIcon={GoogleSVG}
          text={UI.CONTINUE_WITH_GOOGLE}
          color="#6c757d"
          onClick={googleLogin}
        />
        <SocialMediaButton
          SvgIcon={GithubSVG}
          text={UI.CONTINUE_WITH_GITHUB}
          color="#6c757d"
          onClick={githubLogin}
        />
        {/* <SocialMediaButton
          SvgIcon={LinkedInSVG}
          text={UI.CONTINUE_WITH_LINKEDIN}
          color="#6c757d"
        /> */}
      </div>
    </>
  );
}

export default SocailMediaLogin;

SocailMediaLogin.propTypes = {
  isFromSignUp: PropTypes.bool,
};

SocailMediaLogin.defaultProps = {
  isFromSignUp: false,
};
