const ASSET_TYPE = {
  RESUME: 'resume',
  PHOTO: 'photo',
  ID_PROOF: 'idproof',
  LOGO: 'logo',
  ICARD_PROOF: 'icard',
  AUDIO: 'audio',
  VIDEO: 'video',
  ASSESSMENT_QUESTIONS: 'assessment_questions',
  AI_ASSESSMENT_QUESTIONS: 'ai_assessment_questions',
  NEW_COLLEGE: 'new_college',
  UPDATE_VERIFIED_COLLEGES: 'update_verified_colleges',
  UPDATE_VERIFIED_COMPANIES: 'update_verified_companies',
  UPLOAD_WL_DOMAINS: 'upload_wl_domains',
  UPLOAD_BL_DOMAINS: 'upload_bl_domains',
  UPLOAD_INDUSTRIES: 'upload_industries',
  UPLOAD_TAGS: 'upload_tags',
  UPLOAD_CERTIFICATES: 'upload_certificates',
  UPDATE_VERIFIED_INDUSTRIES: 'upload_verified_industries',
  UPDATE_VERIFIED_TITLES: 'upload_verified_titles',
  UPDATE_VERIFIED_TAGS: 'upload_verified_tags',
  UPDATE_VERIFIED_CERTIFICATES: 'update_verified_certificates',
  UPLOAD_TITLES: 'upload_job_titles',
};
export default ASSET_TYPE;
