import { getLoginDetailFromSession } from '../helpers/sessionDetails';
import { getAllowedRolesForAction } from '../formatter/permissions.bootstrap';
import LOGIN_TYPE from '../constants/loginType';
import { EMPLOYER_LOGIN_ESTATE } from '../constants/loginStates.constants';

function hasPermission(action, userRoles) {
  const userDetail = getLoginDetailFromSession();
  const loggedInUserRole = userDetail?.role;
  if (loggedInUserRole === LOGIN_TYPE.CANDIDATE) return true;
  if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING)
    return false;
  const projectRoles = userRoles || userDetail?.projectroles;
  if (!projectRoles) return false;
  const roles = getAllowedRolesForAction(action);
  if (projectRoles && projectRoles.length > 1) {
    return projectRoles.some((role) => roles.includes(role));
  }
  return roles.includes(...projectRoles);
}

export default hasPermission;
