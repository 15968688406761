import {
  getAvailablePointsUser,
  getNotificationCountUser,
} from '../../../../redux/apiCalls';
import { showSnackBar } from '../../../../redux/snackBarSlice';
import {
  setHideLoginDialog,
  setRequestsComplete,
  setRerenderpermissions,
} from '../../../../redux/userSlice';
import GA from '../../../constants/analytics.constants';
import { API_URL } from '../../../constants/apiUrls';
import LOGIN_TYPE from '../../../constants/loginType';
import STORAGE_KEY from '../../../constants/storageKey';
import { getDefaultUrlBasedOnRoleAndState } from '../../../helpers/getRedirectUrl';
import {
  getLoginDetailFromSession,
  setLoginDetailInSession,
} from '../../../helpers/sessionDetails';
import useGAEventsTracker from '../../../hooks/useGAEventsTracker';
import { getRequest } from '../../../services';
import CONTEXT_TYPE from '../../loginDialog/loginDialog.constants';

const searchParams = new URLSearchParams(window.location.search);
const GAEventsTracker = useGAEventsTracker(GA.EVENT_BUTTON.LOGIN);

export const onDialogClose = (dispatch, context) => {
  dispatch(setHideLoginDialog());
  if (context === CONTEXT_TYPE.CANDIDATE_FORM) {
    window.location.reload();
  }
};

export const afterLogin = async ({
  isLoginDialogOpen,
  isSpotOnMockInterview,
  navigate,
  checkProjectProperty,
  getPlanDetails,
  dispatch,
  context,
}) => {
  const userDetail = getLoginDetailFromSession();
  GAEventsTracker(userDetail.name + GA.LOGIN);
  let navigateUrl = searchParams.get('redirectTo');
  let redirectUrl = searchParams.get('redirectTo');

  if (userDetail.role === LOGIN_TYPE.EMPLOYER) {
    const totalprojects = userDetail?.projects?.length;
    const selectedproject = JSON.parse(
      localStorage.getItem(STORAGE_KEY.SELECTED_PROJECT_ID)
    );
    const isUserPartOfProject =
      totalprojects > 0 && userDetail?.projects?.includes(selectedproject);
    let selectedprojectCallUnsuccessful = true;

    if (isUserPartOfProject) {
      await new Promise((resolve) => {
        getRequest(`${API_URL.PROJECT_LOAD}/${selectedproject}`)
          .then(() => {
            navigateUrl =
              navigateUrl ||
              getDefaultUrlBasedOnRoleAndState(
                userDetail.role,
                userDetail.state,
                isSpotOnMockInterview
              );
            redirectUrl = null;
            selectedprojectCallUnsuccessful = false;
            getRequest(API_URL.CREDENTIAL)
              .then((resp) => {
                setLoginDetailInSession(resp);
                getAvailablePointsUser(dispatch);
                getNotificationCountUser(dispatch);
                getPlanDetails(resp?.planid);
              })
              .finally(() => {
                if (selectedprojectCallUnsuccessful) resolve();
                else {
                  const clearCheck = checkProjectProperty(resolve);
                  setTimeout(() => {
                    clearCheck();
                  }, 10000);
                }
              });
          })
          .catch(() => resolve());
      });
    }

    if (selectedprojectCallUnsuccessful) {
      if (totalprojects === undefined || totalprojects === 0) {
        navigateUrl = URL.COMPANY_PROJECTS;
      } else if (totalprojects === 1) {
        await new Promise((resolve) => {
          getRequest(`${API_URL.PROJECT_LOAD}/${userDetail?.projects[0]}`)
            .then(() => {
              localStorage.setItem(
                STORAGE_KEY.SELECTED_PROJECT_ID,
                JSON.stringify(userDetail?.projects[0])
              );

              navigateUrl =
                navigateUrl ||
                getDefaultUrlBasedOnRoleAndState(
                  userDetail.role,
                  userDetail.state,
                  isSpotOnMockInterview
                );

              redirectUrl = null;
              getRequest(API_URL.CREDENTIAL)
                .then((resp) => {
                  setLoginDetailInSession(resp);
                  getAvailablePointsUser(dispatch);
                  getNotificationCountUser(dispatch);
                  getPlanDetails(resp?.planid);
                })
                .finally(() => {
                  const clearCheck = checkProjectProperty(resolve);
                  setTimeout(() => {
                    clearCheck();
                  }, 10000);
                });
            })
            .catch((error) => {
              dispatch(
                showSnackBar({
                  setopen: true,
                  message: error.msg,
                  severity: 'info',
                  duration: 10000,
                })
              );
              resolve();
            });
        });
      } else {
        navigateUrl = URL.MY_PROJECTS;
      }
    }
  } else if (navigateUrl === null) {
    navigateUrl = getDefaultUrlBasedOnRoleAndState(
      userDetail.role,
      userDetail.state,
      isSpotOnMockInterview
    );
  }

  if (userDetail.role === LOGIN_TYPE.CANDIDATE) {
    getAvailablePointsUser(dispatch);
    getNotificationCountUser(dispatch);
    dispatch(setRequestsComplete());
    dispatch(setRerenderpermissions());
  }

  if (isLoginDialogOpen) {
    onDialogClose(dispatch, context);
    window.location.reload();
  }
  if (redirectUrl && userDetail.role === LOGIN_TYPE.EMPLOYER) {
    navigate({
      pathname: navigateUrl,
      search: `redirectTo=${encodeURIComponent(redirectUrl)}`,
    });
  } else {
    navigate(navigateUrl, { replace: true });
  }
};

const handleLogin = async ({
  isLoginDialogOpen,
  setLoggedInUserRole,
  dispatch,
  isSpotOnMockInterview,
  checkProjectProperty,
  navigate,
  getPlanDetails,
  handleEmployerLogin,
  context,
}) => {
  try {
    const resp = await getRequest(API_URL.CREDENTIAL);
    setLoginDetailInSession(resp);
    const loggedInUserRole = resp.role;
    setLoggedInUserRole();
    if (loggedInUserRole === LOGIN_TYPE.CANDIDATE) {
      const response = await getRequest(API_URL.LAST_ASSESSMENT);
      if (response?.showmsg) {
        dispatch(
          showSnackBar({
            setopen: true,
            message: response?.msg,
            severity: 'info',
            duration: 10000,
          })
        );
      }
      afterLogin({
        isLoginDialogOpen,
        isSpotOnMockInterview,
        navigate,
        checkProjectProperty,
        getPlanDetails,
        GAEventsTracker,
        dispatch,
        context,
      });
    } else if (loggedInUserRole === LOGIN_TYPE.EMPLOYER) {
      handleEmployerLogin();
    } else if (
      loggedInUserRole === LOGIN_TYPE.UNIVERSITY ||
      loggedInUserRole === LOGIN_TYPE.ADMIN
    ) {
      afterLogin({
        isLoginDialogOpen,
        isSpotOnMockInterview,
        navigate,
        checkProjectProperty,
        getPlanDetails,
        GAEventsTracker,
        dispatch,
        context,
      });
    }
  } catch (error) {
    dispatch(
      showSnackBar({
        setopen: true,
        message: error?.msg || 'An error occurred',
        severity: 'error',
        duration: 10000,
      })
    );
  }
};

export default handleLogin;
