import React from 'react';

function lazyLoadingFallback() {
  return (
    <div
      style={{ height: window.innerHeight }}
      className="d-flex flex-column align-items-center justify-content-center"
    >
      Loading
    </div>
  );
}

export default lazyLoadingFallback;
