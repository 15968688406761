import PropTypes from 'prop-types';
import { useEffect } from 'react';
import UI from '../constants/ui';

export default function DynamicTitle({ title, description, url }) {
  useEffect(() => {
    document.title = `${title} ${UI.TITLE_ATTACHMENT}`;

    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute('content', description);
    }

    const ogDescriptionMeta = document.querySelector(
      'meta[property="og:description"]'
    );
    if (ogDescriptionMeta) {
      ogDescriptionMeta.setAttribute('content', description);
    }

    const ogTitleMeta = document.querySelector('meta[property="og:title"]');
    if (ogTitleMeta) {
      ogTitleMeta.setAttribute('content', `${title} ${UI.TITLE_ATTACHMENT}`);
    }

    if (url) {
      const canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', url);
      document.head.appendChild(canonicalLink);
    }

    return () => {
      if (url) {
        const existingCanonicalLink = document.querySelector(
          'link[rel="canonical"]'
        );
        if (existingCanonicalLink) {
          document.head.removeChild(existingCanonicalLink);
        }
      }
    };
  }, [title, description, url]);

  return null;
}

DynamicTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
};

DynamicTitle.defaultProps = {
  url: undefined,
};
