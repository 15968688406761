import React, { useEffect } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import Header from '../../../amchome/components/Header';

import HomeBody from '../../../amchome/screens/home/HomeBody';
import BannerSlide from '../../../amchome/screens/home/BannerSlide';
import LOGIN_TYPE from '../../constants/loginType';

function Home(props) {
  const { setShowNavBar } = props;

  useEffect(() => {
    setShowNavBar(false);
  }, []);

  return (
    <div className="header-css">
      <Header />
      <BannerSlide role={LOGIN_TYPE.CANDIDATE} />
      <HomeBody />
    </div>
  );
}

Home.propTypes = {
  setShowNavBar: PropTypes.func,
};

Home.defaultProps = {
  setShowNavBar: noop,
};

export default Home;
