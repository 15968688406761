import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import noop from 'lodash/noop';
import CircularProgress from '@mui/material/CircularProgress';
import makePayment from '../paymentHelper';
import { EMPTY_ARRAY, EMPTY_OBJECT, GST } from '../../../constants';
import PRODUCTS from '../../../constants/products';
import { addScript } from '../../../helpers/addScript';
import { postRequest, getRequest } from '../../../services';
import {
  getTxnTokenUrl,
  API_URL,
  getPricePlanDetails,
} from '../../../constants/apiUrls';
import ErrorSnackBar from '../../../components/snackBar/ErrorSnackBar';
import { getCheckoutUrl } from '../../../formatter/paymentBootstrap';
import { docId } from '../payment.constants';
import UI from '../../../constants/ui';
import {
  getFormattedCurrency,
  getTaxForAmount,
  getAmountWithTax,
} from '../../../formatter/number';
import GA from '../../../constants/analytics.constants';
import useGAEventsTracker from '../../../hooks/useGAEventsTracker';
import {
  getLoginDetailFromSession,
  getPlanDetails,
  setLoginDetailInSession,
  setPlanDetails,
} from '../../../helpers/sessionDetails';
import { BUTTON_TYPE } from '../../../constants/pricingDashboard';
import LOGIN_TYPE from '../../../constants/loginType';
import { setButtonActionWhilePayment } from '../../../../redux/userSlice';

function PriceDetails(props) {
  const { onBack, amount, setTransactionStatus, amountToBeReNew } = props;
  const GAEventsTracker = useGAEventsTracker(GA.EVENT_BUTTON.DIALOG);
  const loaded = useRef(false);
  const typeOfPurchase = useSelector(
    (state) => state.user.buttonActionWhilePayment
  );
  const [isLoading, setIsLoading] = useState(false);
  const [opensnackbar, setSnackbarOpen] = useState();
  const loginDetails = getLoginDetailFromSession();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen({ setopen: false });
  };
  useEffect(() => {
    const scriptSrc = getCheckoutUrl();
    addScript(loaded, scriptSrc, docId);
  }, EMPTY_ARRAY);

  const getUserPlanDetails = (value) => {
    getRequest(getPricePlanDetails(value))
      .then((res) => {
        setPlanDetails(res);
      })
      .catch((err) => {
        setSnackbarOpen({
          setopen: true,
          message: err.msg,
          severity: 'error',
        });
      });
  };

  const dispatch = useDispatch();
  const planDetails = getPlanDetails();

  const handleTransactionStatus = (data) => {
    postRequest(API_URL.PAYMENT_UPDATE, data)
      .then((res) => {
        setTransactionStatus(res);
        if (
          amountToBeReNew?.planid !== planDetails?.planid ||
          amountToBeReNew?.plantype !== planDetails?.plantype
        ) {
          getRequest(API_URL.CREDENTIAL).then((resp) => {
            setLoginDetailInSession(resp);
            getUserPlanDetails(resp?.planid);
            dispatch(setButtonActionWhilePayment(0));
          });
        }
        if (res.httpCode === 'OK') {
          GAEventsTracker(
            `${getLoginDetailFromSession()?.name} ${GA.BUY_POINTS}`
          );

          setSnackbarOpen({
            setopen: true,
            message: res.msg,
            severity: 'success',
          });
        } else {
          setSnackbarOpen({
            setopen: true,
            message: res.msg,
            severity: 'warning',
          });
        }
      })
      .catch((err) => {
        setSnackbarOpen({
          setopen: true,
          message: err.msg,
          severity: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
        window.Paytm.CheckoutJS.close();
      });
  };
  const handleMerchant = () => {
    setIsLoading(false);
    // Call the handleMerchant Action to notify the Merchant about the eventName (eventName)
  };

  const amountWithTax = getAmountWithTax(amount, GST);
  const getTheTxnTokenUrl = () => {
    if (loginDetails?.role === LOGIN_TYPE.EMPLOYER) {
      if (typeOfPurchase === BUTTON_TYPE.RENEW) {
        return getTxnTokenUrl(
          PRODUCTS.POINTS,
          amountWithTax,
          amountToBeReNew?.planid,
          amountToBeReNew?.plantype
        );
      }
      if (typeOfPurchase === BUTTON_TYPE.ADD_POINTS) {
        return getTxnTokenUrl(PRODUCTS.POINTS, amountWithTax, 1, 1);
      }
      if (typeOfPurchase !== BUTTON_TYPE.RENEW || BUTTON_TYPE.ADD_POINTS) {
        return getTxnTokenUrl(
          PRODUCTS.POINTS,
          amountWithTax,
          amountToBeReNew?.planid,
          amountToBeReNew?.plantype
        );
      }
    }

    return getTxnTokenUrl(PRODUCTS.POINTS, amountWithTax, -1, -1);
  };
  const handlePayment = () => {
    setIsLoading(true);

    getRequest(getTheTxnTokenUrl())
      .then((txnData) => {
        makePayment(
          txnData,
          amountWithTax,
          handleMerchant,
          handleTransactionStatus
        )
          .then(() => {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setSnackbarOpen({
          setopen: true,
          message: err.msg,
          severity: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <DialogContent>
        <div className="row">
          <div className="col-8 body-1 color-000000 mt-3 mb-3">{UI.AMOUNT}</div>
          <div className="col-4 body-1 color-000000 mt-3 mb-3">
            {getFormattedCurrency(amount)}
          </div>
          <div className="col-8 body-1 color-000000 mt-3 mb-3">
            {UI.TAX} ({GST}%)
          </div>
          <div className="col-4 body-1 color-000000 mt-3 mb-3">
            {getFormattedCurrency(getTaxForAmount(amount, GST))}
          </div>

          {/* this line/comment use farther */}

          {/* <div className="col-8 body-1 color-F25C05 mt-3 mb-3">
            Discount (10% off)
          </div> 
          <div className="col-4 body-1 color-000000 mt-3 mb-3">0</div> */}

          <hr />
          <div className="col-8 mb-2">{UI.TOTAL_PAYABLE_AMOUNT}</div>
          <div className="col-4 mb-2">
            {getFormattedCurrency(getAmountWithTax(amount, GST))}
          </div>
        </div>
      </DialogContent>
      <div className="d-flex justify-content-end mt-4">
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              onBack(false);
            }}
          >
            <ArrowBackIcon fontSize="small" /> {UI.BACK_BUTTON}
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size="1rem" color="inherit" />
            }
            onClick={handlePayment}
          >
            {UI.MAKE_PAYMENT}
          </Button>
        </DialogActions>
      </div>
      <ErrorSnackBar opensnackbar={opensnackbar} handleClose={handleClose} />
    </>
  );
}

PriceDetails.propTypes = {
  onBack: PropTypes.func,
  amount: PropTypes.number,
  setTransactionStatus: PropTypes.func,
  amountToBeReNew: PropTypes.object,
};
PriceDetails.defaultProps = {
  onBack: noop,
  amount: 0,
  setTransactionStatus: noop,
  amountToBeReNew: EMPTY_OBJECT,
};

export default PriceDetails;
