import { TABLET_SCREEN_WIDTH } from '../constants/screenSize';
import useWindowSize from './useWindowSize';

// Hook
function useTabletDevices() {
  const { windowWidth } = useWindowSize();
  return windowWidth < TABLET_SCREEN_WIDTH;
}

export default useTabletDevices;
